import React, { useContext, createContext, useRef } from 'react';
import { create, useStore } from 'zustand';
import { uuidv4 } from 'common/helpers';
import { immer } from 'zustand/middleware/immer';
import { persist, devtools } from 'zustand/middleware';
import { filter, keys, size, reduce, cloneDeep, isNumber, forEach, sortBy, toPairs } from 'lodash';
import merge from 'deepmerge';
import { eachDayOfInterval, isEqual, addDays, subDays, addMonths } from 'date-fns';

import { STORE_LOCAL_STORAGE_KEY, AVAILABILITY_MODE } from './constants';
import { formatDate } from './utils';

import logger from 'itrvl-logger';
import { OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';
const log = logger(__filename);

const calculateDateMap = segments =>
  reduce(
    segments,
    (acc, segment) => {
      // @todo: what do we do when we have only 1 date?
      let days = [];
      if (isEqual(segment.startDate, segment.endDate)) {
        days.push(segment.startDate);
      } else {
        days = eachDayOfInterval({
          start: segment.startDate,
          end: segment.endDate,
        });
      }
      days.forEach((date, index) => {
        acc[date] = {
          ...segment,
          ...(index === 0 && { first: true }),
          ...(index === days.length - 1 && { last: true }),
        };
      });
      return acc;
    },
    {},
  );

const getInitialState = () => ({
  data: {
    quoteMode: AVAILABILITY_MODE,
    // travelers
    adults: 2,
    children: 0,
    childrenAges: [],
    date: addMonths(new Date(), 3),
    pinnedAccommodationMap: {},
    selectedMap: {},
    client: null,

    excludedDates: [],

    segments: [],

    segmentIds: [],
    segmentsById: {
      // this will be used when you click "view details" on an accommodation
      // @todo: remove this, its for testing
      transient: {
        id: 'transient',
      },
    },
  },

  ui: {
    selectedRoom: null,

    showAvailabilityBySupplierCode: {},

    supplierMonth: {},
    countryAllSelectMap: {},
    onlineMode: false,
    restOfWorldMode: false,

    viewMode: 'list',
    regions: {
      list: null,
      raw: [],
    },
    countries: {
      list: [],
      selectedMap: {},
    },
    date: {
      open: false,
    },
    accommodations: {
      // @todo: change me back to null
      // edit: 'transient',
      edit: null,
      term: '',
      transientTerm: '',
      showAvailability: false,
    },

    dateMap: {},
  },
});

export const ItineraryBuilderContext = createContext(null);

export function ItineraryBuilderStoreProvider({ children, ...props }) {
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createItineraryBuilderStore(props.storeName ?? STORE_LOCAL_STORAGE_KEY);
  }
  if (props.ibStore) {
    // rtl override.
    storeRef.current.getState().actions._mock(props.ibStore);
  }
  return React.createElement(ItineraryBuilderContext.Provider, { value: storeRef.current }, children);
}

// @todo: adding a new data key does not update the localStorage and leaves it off
// we need to merge maybe? with an initial state?
export const useBuilderStore = (selector, equalityFn) => {
  const store = useContext(ItineraryBuilderContext);
  if (!store) throw new Error('Missing ItineraryBuilderContext.Provider in the tree');
  return useStore(store, selector, equalityFn);
};

export const createItineraryBuilderStore = (storeName = STORE_LOCAL_STORAGE_KEY) =>
  create(
    devtools(
      persist(
        immer(set => ({
          api: null,
          ...getInitialState(),
          actions: {
            _mock: mockedState =>
              set(state => {
                keys(mockedState).forEach(key => {
                  state[key] = { ...state[key], ...mockedState[key] };
                });
              }),
            toggleSupplierAvailability: supplierCode =>
              set(state => {
                state.ui.showAvailabilityBySupplierCode[supplierCode] = true;
              }),

            reset: () =>
              set(state => {
                state.data = getInitialState().data;
                state.ui.selectedRoom = null;
                state.ui.onlineMode = false;
                state.ui.restOfWorldMode = state.ui.restOfWorldMode ?? getInitialState().ui.restOfWorldMode;
                state.ui.date.open = false;
                state.ui.countries.selectedMap = {};
                state.ui.accommodations.term = '';
                state.ui.accommodations.transientTerm = '';
                state.ui.accommodations.edit = null;
                state.ui.dateMap = {};
              }),

            setQuoteMode: mode =>
              set(state => {
                state.data.quoteMode = mode;
              }),

            setSupplierMonth: (supplierCode, date) =>
              set(state => {
                state.ui.supplierMonth[supplierCode] = date;
              }),

            setClient: data =>
              set(state => {
                state.data.client = {
                  ...data,
                };
              }),

            clearClient: () =>
              set(state => {
                state.data.client = null;
              }),

            segments: {
              selectRoom: id =>
                set(state => {
                  state.ui.selectedRoom = id;
                  // toggle calendar to the current segments start date, so it changes on select, if provided with an id
                  if (id) {
                    const segment = state.data.segmentsById[id];
                    state.ui.supplierMonth[segment.supplierCode] = segment.startDate;
                  }
                }),
              selectDate: (data, date) =>
                set(state => {
                  const sortSegments = () => {
                    state.data.segmentIds = sortBy(
                      state.data.segmentIds.map(id => state.data.segmentsById[id]),
                      segment => segment.startDate,
                    ).map(segment => segment.id);
                  };

                  const doesExist = dateString => state?.ui?.dateMap?.[dateString];

                  const getLeft = date => doesExist(formatDate(subDays(date, 1)));

                  const getRight = date => doesExist(formatDate(addDays(date, 1)));

                  const deleteSegment = (dateString, segmentId) => {
                    delete state.ui.dateMap[dateString];
                    delete state.data.segmentsById[segmentId];
                    state.data.segmentIds = state.data.segmentIds.filter(sid => sid !== segmentId);
                  };

                  const createSegment = (startDate, endDate, data) => {
                    const segment = {
                      startDateString: formatDate(startDate),
                      startDate,
                      endDateString: formatDate(endDate),
                      endDate,
                      id: uuidv4(),
                      ...data,
                    };
                    state.data.segmentsById[segment.id] = segment;
                    state.data.segmentIds.push(segment.id);
                    eachDayOfInterval({
                      start: startDate,
                      end: endDate,
                    }).forEach(date => {
                      const dateString = formatDate(date);
                      state.ui.dateMap[dateString] = segment.id;
                    });

                    // on creating a new segment, let's auto select it
                    state.ui.selectedRoom = segment.id;

                    return segment;
                  };

                  const dateString = formatDate(date);

                  const matches = (dateString, data) => {
                    const segment = state?.data?.segmentsById?.[dateString];
                    return segment && segment.supplierCode === data.supplierCode && !segment.locked ? segment : undefined;
                  };

                  const exists = doesExist(dateString);
                  const left = getLeft(date);
                  const right = getRight(date);
                  const matchLeft = matches(left, data);
                  const matchRight = matches(right, data);

                  // 1. - 0 - | no exist, no match on either side, add
                  if (!exists && !matchLeft && !matchRight) {
                    log.trace('opt1');
                    createSegment(date, date, data);
                  }

                  // 2. + 0 - | no exist, match left, no match right combine
                  if (!exists && matchLeft && !matchRight) {
                    log.trace('opt2');
                    // set endDate of left to match date
                    matchLeft.endDate = date;
                    matchLeft.endDateString = formatDate(date);
                    state.ui.dateMap[dateString] = matchLeft.id;
                  }

                  // 3. - 0 + | no exist, match right, no match left, combine
                  if (!exists && !matchLeft && matchRight) {
                    log.trace('opt3');
                    // set startDate of right to match
                    matchRight.startDate = date;
                    matchRight.startDateString = formatDate(date);
                    state.ui.dateMap[dateString] = matchRight.id;
                  }

                  // 4. + 0 + | no exist, match both, combine
                  if (!exists && matchLeft && matchRight) {
                    log.trace('opt4');
                    // bridge both segments
                    // set left endDate to right endDate
                    // delete right segment
                    matchLeft.endDate = matchRight.endDate;
                    matchLeft.endDateString = formatDate(matchRight.endDate);
                    eachDayOfInterval({
                      start: matchLeft.startDate,
                      end: matchLeft.endDate,
                    }).forEach(date => {
                      const dateString = formatDate(date);
                      state.ui.dateMap[dateString] = matchLeft.id;
                    });
                    state.data.segmentIds = state.data.segmentIds.filter(segmentId => segmentId !== matchRight.id);
                    if (state.data.segmentsById[matchLeft.id].rooms || state.data.segmentsById[matchRight.id].rooms) {
                      state.data.segmentsById[matchLeft.id].rooms =
                        state.data.segmentsById[matchLeft.id].rooms ?? state.data.segmentsById[matchRight.id].rooms; // Keep rooms.
                    }
                    delete state.data.segmentsById[matchRight.id];
                  }

                  // 5. + + + | exist, match both, split
                  if (exists && matchLeft && matchRight) {
                    log.trace('opt5');
                    // delete from middle, make new segment on right
                    // |---seg1---| |-del-| |---seg2---|
                    const newSegment = createSegment(addDays(date, 1), matchLeft.endDate, data);
                    eachDayOfInterval({
                      start: newSegment.startDate,
                      end: newSegment.endDate,
                    }).forEach(date => {
                      const dateString = formatDate(date);
                      state.ui.dateMap[dateString] = newSegment.id;
                    });
                    matchLeft.endDate = subDays(date, 1);
                    matchLeft.endDateString = formatDate(subDays(date, 1));
                    if (state.data.segmentsById[matchLeft.id].rooms) {
                      newSegment.rooms = merge({}, state.data.segmentsById[matchLeft.id].rooms); // Keep rooms.
                    }
                    delete state.ui.dateMap[dateString];
                  }

                  // 6. + + - | exist, match left, no match right, truncate
                  if (exists && matchLeft && !matchRight) {
                    log.trace('opt6');
                    // remove one day from endDate left
                    matchLeft.endDate = subDays(date, 1);
                    matchLeft.endDateString = formatDate(subDays(date, 1));
                    delete state.ui.dateMap[dateString];
                  }

                  // 7. - + + | exist, match right, no match left, truncate
                  if (exists && !matchLeft && matchRight) {
                    log.trace('opt7');
                    // remove one day from startDate right
                    matchRight.startDate = addDays(date, 1);
                    matchRight.startDateString = formatDate(addDays(date, 1));
                    delete state.ui.dateMap[dateString];
                  }

                  // 8. - + - | exist, no match, delete
                  if (exists && !matchLeft && !matchRight) {
                    log.trace('opt8');
                    // delete
                    deleteSegment(dateString, exists);
                  }

                  sortSegments();
                }),
            },

            copyToBuilder: {
              setData: data =>
                set(state => {
                  state.data = { ...state.data, ...data };
                  state.data.childrenAges = data.childrenAges;
                  state.data.segmentIds = data.segmentIds;
                  state.data.pinnedAccommodationMap = data.pinnedAccommodationMap;
                }),
              setCampSegment: camp =>
                set(state => {
                  const segments = filter(state.data.segmentsById, { supplierCode: camp.supplierCode });
                  for (let segment of segments) {
                    // Set camp values we do not store in db.
                    segment.country = camp.country;
                    segment.campInfo = camp.campInfo;
                    segment.campFeatures = camp.campFeatures;
                    segment.dmcArrangedOnly = camp.dmcArrangedOnly;
                    segment.online = camp.online;
                    segment.consultantInteractionRequired = camp.consultantInteractionRequired;
                    segment.restOfWorld = camp.restOfWorld;
                    segment.minChildAge = camp.minChildAge;
                    segment.regionName = camp.regionName;
                    segment.campName = camp.campName; // Set (again) for camps that originally erred.
                  }
                }),
              clearPin: () =>
                set(state => {
                  state.data.pinAccommodations = [];
                }),
              clearOwn: () =>
                set(state => {
                  state.data.ownAccommodations = [];
                }),
              setOwnSegment: region =>
                set(state => {
                  const segments = filter(state.data.segmentsById, {
                    supplierCode: OWN_ACCOMMODATION_SUPPLIER_CODE,
                    regionCode: region.regionCode,
                  });
                  for (let segment of segments) {
                    // Set region values we do not store in db.
                    segment.country = region.country;
                    segment.regionName = region.name;
                  }
                }),
              clearUiDateMap: () =>
                set(state => {
                  state.ui.dateMap = {};
                }),
              setUiDateMap: dateMap =>
                set(state => {
                  state.ui.dateMap = { ...state.ui.dateMap, ...dateMap };
                }),
            },

            api: {
              setApi: api =>
                set(state => {
                  state.api = api;
                }),
            },

            settings: {
              setFilter: restOfWorldMode =>
                set(state => {
                  state.ui.restOfWorldMode = restOfWorldMode;
                  // @todo: this is mutating global initial state, we should fix this once we know intent
                  // initialState.ui.restOfWorldMode = restOfWorldMode;
                }),
            },

            regions: {
              setFilteredRegions: data =>
                set(state => {
                  state.ui.regions.list = data;
                }),
              setRegions: data =>
                set(state => {
                  state.ui.regions.raw = data;
                }),
              toggleRegion: item =>
                set(state => {
                  if (item.value in state.ui.countries.selectedMap) {
                    delete state.ui.countries.selectedMap[item.value];
                  } else {
                    state.ui.countries.selectedMap[item.value] = item;
                  }
                }),
              removeAllRegions: () =>
                set(state => {
                  state.ui.countries.selectedMap = {};
                }),
              removeRegion: id =>
                set(state => {
                  delete state.ui.countries.selectedMap[id];
                }),
            },

            countries: {
              setCountries: countries =>
                set(state => {
                  state.ui.countries.list = countries;
                }),

              removeAllCountryRegions: (countryCode, values) =>
                set(state => {
                  delete state.ui.countryAllSelectMap[countryCode];
                  forEach(values, value => {
                    delete state.ui.countries.selectedMap[value.value];
                  });
                }),
              addAllRegions: (countryCode, values) =>
                set(state => {
                  state.ui.countryAllSelectMap[countryCode] = true;

                  forEach(values, value => {
                    state.ui.countries.selectedMap[value.value] = value;
                  });
                }),
              // @todo: this is a dupe of the region stuff
              setSelected: (id, data) =>
                set(state => {
                  if (id in state.ui.countries.selectedMap) {
                    delete state.ui.countries.selectedMap[id];
                  } else {
                    state.ui.countries.selectedMap[id] = data;
                  }

                  // remove "all" if something is selected
                  if (size(keys(state.ui.countries.selectedMap).filter(key => key !== 'all')) > 0) {
                    delete state.ui.countries.selectedMap.all;
                  }
                }),
            },
            accommodations: {
              updateRoomOnSegment: (segmentId, roomId, data) =>
                set(state => {
                  const match = state.data.segmentsById[segmentId].rooms.find(room => room.id === roomId);
                  if (match) {
                    toPairs(data).forEach(([k, v]) => {
                      match[k] = v;
                    });
                  }
                }),
              removeRoom: (segmentId, roomId) =>
                set(state => {
                  state.data.segmentsById[segmentId].rooms = state.data.segmentsById[segmentId].rooms.filter(room => room.id !== roomId);
                }),
              addRoomToSegment: (id, data) =>
                set(state => {
                  state.data.segmentsById[id].rooms = [...(state.data.segmentsById[id].rooms || []), { id: uuidv4(), ...data }];
                  //state.data.segmentsById[id].locked = true; // Disable lock logic for now.
                }),
              editSegmentById: id =>
                set(state => {
                  // set the selected date to the segments month so we aren't out-of-bounds
                  const segment = state.data.segmentsById[id];
                  state.ui.supplierMonth[segment.supplierCode] = segment.startDate;

                  // @todo: we should probably do away with transient as its no long _really_ used
                  // populate transient
                  state.ui.accommodations.edit = 'transient';
                  const item = { ...segment };
                  delete item.id;
                  delete item.startDate;
                  delete item.endDate;
                  delete item.locked;
                  state.data.segmentsById.transient = {
                    ...item,
                    item,
                    id: 'transient',
                  };

                  // auto select the room
                  state.ui.selectedRoom = segment.id;
                }),
              patchSegment: (id, patchData) =>
                set(state => {
                  const segment = state.data.segmentsById[id];
                  state.data.segmentsById[id] = {
                    ...segment,
                    ...patchData,
                  };
                }),
              deleteSegmentById: id =>
                set(state => {
                  const segment = state.data.segmentsById[id];
                  if (segment) {
                    eachDayOfInterval({
                      start: segment.startDate,
                      end: segment.endDate,
                    }).forEach(date => {
                      const dateString = formatDate(date);
                      delete state.ui.dateMap[dateString];
                    });
                    state.data.segmentIds = state.data.segmentIds.filter(segmentId => segmentId !== id);
                    delete state.data.segmentsById[id];
                  }
                }),
              updateSegmentDates: (id, startDate, endDate) =>
                set(state => {
                  const match = state.data.segmentsById[id];
                  match.startDate = startDate;
                  match.endDate = endDate;
                  // nuke dateMap as well
                  forEach(state.ui.dateMap, (v, k) => {
                    if (v.id === id) {
                      delete state.ui.dateMap[k];
                    }
                  });
                }),

              clearSearch: () =>
                set(state => {
                  state.ui.accommodations.term = '';
                  state.ui.accommodations.transientTerm = '';
                }),
              setOnlineMode: mode => {
                return set(state => {
                  state.ui.onlineMode = mode;
                });
              },
              setRestOfWorldMode: mode => {
                return set(state => {
                  state.ui.restOfWorldMode = mode;
                });
              },
              setViewMode: mode =>
                set(state => {
                  state.ui.viewMode = ['list', 'card'].includes(mode) ? mode : state.ui.viewMode;
                }),

              // @todo: these are the same and need to be groomed
              viewDetails: data =>
                set(state => {
                  state.ui.accommodations.edit = 'transient';
                  state.data.segmentsById.transient = {
                    id: 'transient',
                    ...data,
                  };

                  // if we click view details, lets auto select the first matching segment and set it
                  // as selected, so we can just move forward without extra ops
                  if (
                    !state.ui.selectedRoom ||
                    (state.ui.selectedRoom &&
                      state.data.segmentsById?.[state.ui.selectedRoom] &&
                      state.data.segmentsById?.[state.ui.selectedRoom]?.supplierCode !== data?.supplierCode)
                  ) {
                    const match = state.data.segmentIds.find(id => state.data.segmentsById[id]?.supplierCode === data?.supplierCode);
                    if (match) {
                      state.ui.selectedRoom = match;
                    }
                  }
                }),
              editSegmentAtIndex: index =>
                set(state => {
                  // we should probably snapshot this data, so that we don't lose it when we delete?
                  // this can either just be a "view and select" mode or "edit" for a current segment
                  state.ui.accommodations.edit = isNumber(index) ? cloneDeep(state.data.segments[index]) : index;

                  if (!index) {
                    // clear the "selected room"
                    state.ui.selectedRoom = null;
                  }
                }),

              ///
              removeSegmentAtIndex: index =>
                set(state => {
                  state.data.segments = [...state.data.segments.slice(0, index), ...state.data.segments.slice(index + 1)];
                  state.ui.dateMap = calculateDateMap(state.data.segments);
                }),

              addTransientToItinerary: () =>
                set(state => {
                  // create a clone of the transient and push it to segments
                  const id = uuidv4();
                  const segment = {
                    ...state.data.segmentsById.transient,
                    id,
                  };
                  state.data.segmentsById[id] = segment;
                  state.data.segments.push(id);
                  // reset the transient
                  state.data.segmentsById.transient = {
                    id: 'transient',
                  };
                  // update the edit target
                  state.ui.accommodations.edit = id;
                }),
              addSupplierToItinerary: data =>
                set(state => {
                  const { supplierCode } = data;
                  const [startDate, endDate] = state.data.selectedMap[supplierCode];
                  const id = uuidv4();
                  const segment = {
                    ...data,
                    startDate,
                    endDate,
                    // lets give a uuid so we don't have to match on index, which could shift during edit
                    id,
                  };

                  state.data.segments.push(id);
                  state.data.segmentsById[id] = segment;

                  // get the segments and sort them by startDate

                  const segments = state.data.segments.map(id => state.data.segmentsById[id]);
                  state.data.segments = segments.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)).map(segment => segment.id);

                  // @todo: we can move this to a custom hook most likely
                  state.ui.dateMap = calculateDateMap(segments);

                  delete state.data.selectedMap[supplierCode];
                  // copy data from select map to itinerary drawer
                }),

              selectDates: (supplierCode, dates) =>
                set(state => {
                  const [startDate, endDate] = dates;
                  state.data.selectedMap[supplierCode] = dates;

                  // @todo: are we even using excludedDates anymore? i don't beleive we are
                  state.data.excludedDates.push({
                    start: startDate,
                    // partial range clicks result in a null second value
                    end: endDate ? endDate : startDate,
                    supplierCode,
                  });
                  // state.data.excludedDates = values(state.data.selectedMap).map(
                  //   ([startDate, endDate]) => ({
                  //     start: startDate,
                  //     // partial range clicks result in a null second value
                  //     end: endDate ? endDate : startDate,
                  //   })
                  // );
                  // state.data.excludedDates.push();
                }),
              setShowAvailability: () =>
                set(state => {
                  state.ui.accommodations.showAvailability = true;
                }),

              setTransientTerm: term =>
                set(state => {
                  state.ui.accommodations.transientTerm = term;
                }),
              setSearchTerm: async term =>
                set(state => {
                  state.ui.accommodations.term = term;
                  // const fetchId = ++state.ui.search.fetchId;
                  // await new Promise( resolve => {
                  // });
                }),
              toggleAccommodationPin: (camp, { keep } = {}) =>
                set(state => {
                  // @todo: null safe this
                  if (camp.supplierCode in state.data.pinnedAccommodationMap) {
                    if (!keep) delete state.data.pinnedAccommodationMap[camp.supplierCode];
                  } else {
                    state.data.pinnedAccommodationMap[camp.supplierCode] = {
                      ...camp,
                    };
                  }
                }),
            },
            travelers: {
              incrementChildren: delta =>
                set(state => {
                  let value = state.data.children + delta;
                  value = value < 0 ? 0 : value > 6 ? 6 : value;
                  state.data.children = value;
                }),

              setChildrenAges: childrenAges =>
                set(state => {
                  state.data.childrenAges = childrenAges;
                }),
              setTravelersByType: (type, to) =>
                set(state => {
                  state.data[type] = to;
                }),
            },
            date: {
              setDate: date =>
                set(state => {
                  state.data.date = date;
                  // reset selected supplier months so all calendars change
                  state.ui.supplierMonth = {};
                }),
              toggleDatePicker: () =>
                set(state => {
                  state.ui.date.open = !state.ui.date.open;
                }),
              openDatePicker: () =>
                set(state => {
                  state.ui.date.open = true;
                }),
              closeDatePicker: () =>
                set(state => {
                  state.ui.date.open = false;
                }),
            },
          },

          test: {
            value: 0,
            incrementValue: () =>
              set(state => {
                state.test.value = state.test.value + 1;
              }),
          },
        })),
        {
          name: storeName,
          onRehydrateStorage: _state => {
            // log.debug('hydration starts');
            // optional
            return (_state, error) => {
              if (error) {
                log.warn('an error happened during hydration', error);
              } else {
                log.debug('hydration finished');
              }
            };
          },
          // only capture data
          partialize: ({ data }) => ({ data }),
          deserialize: state => {
            const json = JSON.parse(state);

            // log.debug('json:pre', json);
            // manually handle date parsing
            if (json?.state?.data?.date) {
              json.state.data.date = new Date(json.state.data.date);
            }
            // @todo: remove this later, we need to handle dates for selectedMap
            json.state.data.selectedMap = {};
            json.state.data.excludedDates = [];

            const segments = json.state.data.segmentIds.map(id => {
              const segment = json.state.data.segmentsById[id];
              segment.startDate = new Date(segment.startDate);
              segment.endDate = new Date(segment.endDate);
              return segment;
            });

            const dateMap = reduce(
              segments,
              (acc, segment) => {
                eachDayOfInterval({
                  start: segment.startDate,
                  end: segment.endDate,
                }).forEach(date => {
                  const dateString = formatDate(date);
                  acc[dateString] = segment.id;
                });
                return acc;
              },
              {},
            );

            json.state.ui = {
              // dateMap: calculateDateMap(segments),
              dateMap,
            };

            // log.debug('json:post', json);

            return json;
          },
          merge: (persistedState = {}, currentState = {}) => {
            // log.debug('merge:pre', persistedState, currentState);
            const state = merge(currentState, persistedState);
            // log.debug('merge:post', state);
            return state;
          },
        },
      ),
    ),
  );
