import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { groupBy, map, size, orderBy, reduce } from 'lodash';
import clsx from 'clsx';
import { OWN_ACCOMMODATION_SUPPLIER_CODE, rateKey } from 'itrvl-types';

import { Button, CircularProgress } from '@mui/material';
import RoomCard from './RoomCard';
import DetailSegments from './DetailSegments';
import Image from './Image';
import AvailabilityCalendar from './AvailabilityCalendar';
import CampFeatures from './CampFeatures';

import SupportIcon from './icons/Support';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import ChildRestrictions from './ChildRestrictions';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import logger from 'itrvl-logger';

import { useBuilderStore } from './store';
import { useRoomsForSupplierQuery, useInclusionsBySupplierQuery } from './queries';
import { useQuoteOnly, useSupplierBounds } from './hooks';
const log = logger(__filename);
log.trace(__filename);

const GUTTER = 37;
const useStyles = makeStyles(
  theme => ({
    root: {
      inset: 0,
      backgroundColor: '#f7f4f1',
      zIndex: 3,
      overflowY: 'auto',
      // so we can scroll the container with the horizontal nav below
      paddingBottom: 100,

      position: 'fixed',
      // unsure why this is needed, chrome was crossing out the style
      top: '69px !important',

      '& .ib2-ac-current-selection': {
        '& > div > span': {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
        },
      },
    },
    content: {
      position: 'static',
    },
    hero: {
      position: 'relative',
      display: 'flex',
      gap: 40,
      padding: GUTTER,
    },
    data: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      gap: 40,
    },
    info: {
      position: 'relative',
      flex: 1,
      '& h1': {
        color: '#fff',
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 48,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    back: {
      color: '#fff',
    },
    image: {
      position: 'absolute',
      inset: 0,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    overlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    calendar: {
      position: 'relative',
      backgroundColor: '#fff',
    },
    // about
    about: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gap: 80,
      padding: GUTTER,
      borderBottom: '1px solid #E9E1D7',
      '& h2': {
        margin: 0,
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 36,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    // rooms
    allRooms: {
      padding: GUTTER,
      paddingBottom: 200,
    },
    rooms: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    room: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      justifyContent: 'flex-end',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
    },
    support: {
      paddingBottom: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      '& svg': {
        color: theme.palette.primary.main,
        width: 40,
      },
    },
    features: {
      display: 'flex',
      gap: 20,
    },
    featuresSection: {
      paddingTop: 25,
      marginTop: 12,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    onlineIcon: {
      width: 25,
      height: 'auto',
    },
  }),
  {
    name: 'ItineraryBuilderDetailsContent',
  },
);

const DetailsContent = props => {
  const classes = useStyles();
  const { id } = props;
  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const { supplierCode, campInfo, campName, dmcArrangedOnly, campFeatures, online, restOfWorld, consultantInteractionRequired } = segment;
  const selectedSegment = useBuilderStore(state => state.data.segmentsById[state.ui.selectedRoom]) || {};
  const { startDate, endDate } = selectedSegment;
  const isQuoteOnly = useQuoteOnly();
  const editSegmentAtIndex = useBuilderStore(state => state.actions.accommodations.editSegmentAtIndex);
  const { startBound, endBound } = useSupplierBounds(supplierCode);
  const roomsQuery = useRoomsForSupplierQuery(supplierCode, selectedSegment);

  log.info(`DetailsContent for ${campName} on ${startDate} - ${endDate}, quote only: ${isQuoteOnly}`);
  log.info('  selectedSegment: ', selectedSegment);

  const selectedRoomsMap = reduce(
    selectedSegment.rooms,
    (acc, room) => {
      const key = rateKey(room.room);
      if (!acc?.[key]) {
        acc[key] = 1;
      } else {
        acc[key]++;
      }
      return acc;
    },
    {},
  );

  const rooms = useMemo(() => {
    return roomsQuery?.data?.[supplierCode]?.Any?.options || [];
  }, [roomsQuery?.data, supplierCode]);

  // @todo: pipe in startDate and endDate
  const inclusionsQuery = useInclusionsBySupplierQuery(supplierCode, startBound, endBound);

  const roomsByType = useMemo(() => {
    let data = rooms;
    data = orderBy(data, ['systemType', 'name'], ['desc', 'asc']);
    data = groupBy(data, 'systemType');
    // rename _Double to Double
    if (data._Double) {
      data.Double = data._Double;
      delete data._Double;
    }
    return data;
  }, [rooms]);

  // @todo: this may change, depending
  // const canAddToDrawer = id === 'transient' && startDate && endDate;

  const hasNoRooms = roomsQuery?.data && size(roomsQuery.data) === 0;
  const noRoomSelections = dmcArrangedOnly || hasNoRooms;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.hero}>
          {/* @todo: backdrop image goes here! */}
          <div className={classes.image}>
            <div className={classes.overlay} />
            <Image s3Key={segment?.featuredImage?.s3Key} />
          </div>

          <div className={clsx(classes.data, 'inner')}>
            <div className={classes.info}>
              <Button className={classes.back} onClick={() => editSegmentAtIndex(null)}>
                <ChevronLeftIcon /> View All Accommodations
              </Button>
              <h1>{campName}</h1>
            </div>
            <div className={classes.calendar}>
              <AvailabilityCalendar data={segment.item} supplierCode={supplierCode} enabled={true} />
            </div>
          </div>
        </div>

        <DetailSegments supplierCode={supplierCode} noRoomSelections={noRoomSelections} />

        <div className={clsx(classes.about, 'inner')}>
          <div>
            <h2>About {campName}</h2>
            <div className={classes.featuresSection}>
              <div className={classes.features}>
                {size(campFeatures) > 0 && campFeatures[0] !== '' && <CampFeatures campFeatures={campFeatures} />}
                <ChildRestrictions item={segment.item} />
              </div>
              <div className={classes.features}>
                {online === true && <ItrvlIcon name="live" className={classes.onlineIcon} />}
                {process.env.REACT_APP_DEBUG_IB2 && restOfWorld === true && <ItrvlIcon name="world" className={classes.onlineIcon} />}
                {process.env.REACT_APP_DEBUG_IB2 && consultantInteractionRequired === true && (
                  <ItrvlIcon name="zero" className={classes.onlineIcon} />
                )}
              </div>
            </div>
          </div>
          {campInfo && <div>{campInfo}</div>}
        </div>

        <div className={clsx(classes.allRooms, 'inner')} data-test-id="rooms-types-container">
          {noRoomSelections && supplierCode !== OWN_ACCOMMODATION_SUPPLIER_CODE && (
            <div className={classes.support}>
              <SupportIcon /> Please contact your agent to discuss room booking options at this accommodation.
            </div>
          )}
          {!noRoomSelections && roomsQuery.isFetching && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
          {!noRoomSelections && !roomsQuery.isFetching && (
            <>
              {size(rooms) > 0 && (
                <>
                  <h2>Room Types ({size(rooms)})</h2>
                  {map(roomsByType, (rooms, key) => {
                    return (
                      <div key={key} data-test-id="room-types-option">
                        <h3>
                          {key} ({size(rooms)})
                        </h3>
                        <div className={classes.rooms}>
                          {map(rooms, room => {
                            const key = rateKey(room);

                            return (
                              <div className={classes.room} key={key} data-test-id={key}>
                                <RoomCard
                                  canFill={selectedSegment?.supplierCode === supplierCode}
                                  segmentId={selectedSegment?.id}
                                  disabled={room.disabled}
                                  numAlreadySelected={selectedRoomsMap?.[key] || 0}
                                  hasDates={selectedSegment?.startDate && selectedSegment?.endDate}
                                  room={room}
                                  key={room.roomTypeId}
                                  inclusionsLoading={inclusionsQuery.isFetching}
                                  inclusions={inclusionsQuery?.data && inclusionsQuery.data[key]}
                                  isQuoteOnly={isQuoteOnly}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailsContent;
