import axios from 'axios';
import { find, set, get, map, reduce, uniq, orderBy } from 'lodash';
import CommonApi, { BindToClass } from 'common/utils/Api';
import logger from 'itrvl-logger';
import Transitpoints from './facade/TransitPoints';
import EditItinerary from './facade/EditItinerary';
import Rates from './facade/Rates';

const log = logger(__filename);

const buildThreads = data =>
  data.reduce((ret, message) => {
    if (!ret[message.conversation]) {
      ret[message.conversation] = [];
    }
    ret[message.conversation].push(message);
    return ret;
  }, {});

// Auto-prepend API URL subpath & optionally append URL-friendly filter parameter
const APIizeURL = urlFragment => `/api/${urlFragment}`.replace('//', '/');
//TODO - Investigate no sql injection here
const filterizeURL = (urlFragment, filter) => {
  const wasSentFilter = typeof filter !== 'undefined';
  return wasSentFilter ? `${urlFragment}?filter=${JSON.stringify(filter)}` : urlFragment;
};

const filterizeAPIURL = (urlFragment, filter) => APIizeURL(filterizeURL(urlFragment, filter));

const createAPIDataRequestorWithVerb = function(verb) {
  return async function(type, ...args) {
    const url = APIizeURL(type);
    try {
      const request = await this[verb](url, ...args);
      // TODO: determine if non-200 statūs (e.g. 20*–30*) are also acceptable
      log.debug(`[${verb.toUpperCase()}:${url}] request.status: ${request.status}`);
      if (request.status === 200) {
        return request.data;
      }
    } catch (err) {
      // TODO: should we use `console.error`?
      log.warn('error getting request', url, err);
      throw err;
    }
  };
};

const threadizeItineraryMessages = ({ data: { itineraries }, ...itineraryContainer }) => {
  return {
    ...itineraryContainer,
    data: itineraries.map(itinerary => ({
      ...itinerary,
      threads: buildThreads(itinerary.messages),
    })),
  };
};

const getData = createAPIDataRequestorWithVerb('get');
const postData = createAPIDataRequestorWithVerb('post');

export default class Api extends CommonApi {
  constructor(server) {
    super(server);
    this.getData = getData;
    this.postData = postData;
    // name spaced the endpoints for clarity
    this.transitPoints = {};
    this.editItinerary = {};

    BindToClass(Transitpoints, this, 'transitPoints');
    BindToClass(EditItinerary, this, 'editItinerary');
    BindToClass(Rates, this);
  }

  downloadPricingCsv(id) {
    return this.axios({
      method: 'GET',
      url: `/api/Itineraries/${id}/csv`,
      responseType: 'blob',
    });
  }

  getClientDetailsFromClient(clientId) {
    return this.get(`/api/Clients?filter[where][id]=${clientId}`).then(response => response.data[0]);
  }
  getClientDetailsWithUpdates(clientId) {
    const filter = {
      include: [
        {
          relation: 'clientUpdates',
          scope: {
            include: [{ relation: 'agent' }, { relation: 'agency' }, { relation: 'itinerary', scope: { fields: 'name' } }],
            order: ['timestamp desc', 'id desc'],
            limit: 50,
          },
        },
        { relation: 'agent' },
      ],
    };
    return this.get(`/api/Clients/${clientId}` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  getFlightStatus(airlineCode, flightNumber, [year, month, day]) {
    return this.get(`/api/FlightStatus/getFlightStatus/${airlineCode}/${flightNumber}/${year}/${month}/${day}`);
  }
  verifyToken(verificationToken, uid) {
    return this.get(`/api/Agents/confirm/?uid=${uid}&token=${verificationToken}`);
  }
  verifyTokenAndLogin(verificationToken, uid) {
    return this.get(`/api/Agents/confirmAndLogin/?uid=${uid}&token=${verificationToken}`);
  }
  patchClient(clientId, payload = {}) {
    return this.patch(`/api/Clients/${clientId}`, { ...payload });
  }
  setClientEmail(clientId, email) {
    return this.patch(`/api/Clients/${clientId}`, { email });
  }
  reassignAgent(clientId, agentId) {
    return this.post(`/api/Clients/reassign-agent`, { clientId, agentId });
  }
  updateClient(clientId, payload) {
    // cleanup excessive payload
    delete payload.clientNotes;
    delete payload.clientUpdates;
    return this.patch(`/api/Clients/${clientId}`, { ...payload });
  }
  updateClientStatus(payload) {
    return this.post(`/api/Clients/status-update`, { ...payload });
  }
  addClientNote(payload) {
    return this.post(`/api/ClientNotes`, { ...payload });
  }
  deleteClient(clientId) {
    return this.delete(`/api/Clients/${clientId}`);
  }
  archiveClient(clientId, archived) {
    return this.patch(`/api/Clients/${clientId}`, { archived });
  }
  createClient(data) {
    return this.post('/api/Clients', data).then(resp =>
      this.get(`/api/Clients?filter[where][id]=${resp.data.id}`).then(response => response.data[0]),
    );
  }

  async createClientNext(data) {
    let response = await this.post('/api/Clients', data);
    response = await this.get(filterizeAPIURL(`Clients/${response.data.id}`, {}));
    return response?.data;
  }

  getClients(where) {
    const url = filterizeAPIURL('Clients', {
      where,
      fields: ['id', 'email', 'agentId', 'contact', 'archived', 'firstName', 'lastName', 'name', 'status', 'updatedDate'],
      include: [
        {
          relation: 'agency',
          scope: {
            fields: {
              agencyCode: true,
              name: true,
            },
          },
        },
        {
          relation: 'agent',
          scope: {
            fields: {
              firstName: true,
              lastName: true,
              fullName: true,
              headshotUri: true,
            },
          },
        },
      ],
      order: ['timestamp DESC', 'id DESC'],
    });
    return this.get(url);
  }
  getClientsCount(where) {
    const url = filterizeAPIURL('Clients/count', {
      where,
    });
    return this.get(url);
  }

  agentGetClients(order = null, dropSuper = false) {
    return this.get(
      `/api/Clients?filter[include]=agent${order ? `&[order]=${order} ASC` : ''}${dropSuper ? `&filter[dropSuper]=${dropSuper}` : ''}`,
    );
  }
  agentGetClientsCount(where) {
    return this.get('/api/Clients/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }

  agentGetClientsWithLatestUpdate(order = null, dropSuper = false) {
    let filter = {};
    if (order) {
      filter.order = `${order} ASC`;
    }
    if (dropSuper) {
      filter.dropSuper = dropSuper;
    }
    filter.include = [
      {
        relation: 'clientUpdates',
        scope: {
          order: ['timestamp desc', 'id desc'],
          limit: 1,
        },
      },
      { relation: 'agent' },
    ];

    return this.get(`/api/Clients` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  agentForgotPassword(email) {
    return this.post('/api/Agents/reset', { email });
  }
  agentResetPassword(token, password) {
    return this.post(`/api/Agents/reset-password?access_token=${token}`, { newPassword: password });
  }
  agentRegister(firstName, lastName, email, password, agencyName) {
    return this.post('/api/Agents', {
      fullName: `${firstName} ${lastName}`,
      firstName,
      lastName,
      agencyName,
      email,
      password,
      sendInvite: true,
    });
  }
  agentResendConfirmation(email) {
    return this.post('/api/Agents/resendConfirmation', { email });
  }
  agentLogin(email, password) {
    return this.post('/api/Agents/login', {
      email,
      password,
    });
  }

  loginAsUser(userId, password) {
    return this.post('/api/Agents/loginAsUser', { userId, password });
  }

  // Agencies
  getWildernessAgency(wildCode) {
    return this.get(`/api/WindowAPIs/getAgency?wildCode=${wildCode}`);
  }
  getAgencyAgents(agencyId) {
    return this.get(`/api/Agencies/${agencyId}/agents`);
  }
  getAgency(agencyId) {
    return this.get(`/api/Agencies/${agencyId}`);
  }
  getAgencies(filter) {
    // Include agents if no include specified
    if (!get(filter, 'include')) {
      if (!filter) {
        filter = {};
      }
      filter.include = { relation: 'agents' };
    }
    return this.get(filterizeAPIURL('Agencies', filter));
  }
  getAgencyDetails(agencyId) {
    return this.get(filterizeAPIURL(`Agencies/${agencyId}`, { include: ['agents', 'termsandconditions', 'lightlogos'] }));
  }
  getMyAgencySettings(agencyId, query = {}) {
    return this.get(filterizeAPIURL(`Agencies/${agencyId}`, query));
  }
  archiveAgency(AgencyId, archived) {
    return this.patch(`/api/Agencies/${AgencyId}`, { archived });
  }
  deleteAgency(AgencyId) {
    return this.delete(`/api/Agencies/${AgencyId}`);
  }
  createAgency(data) {
    return this.post('/api/Agencies', data);
  }
  patchAgency(AgencyId, payload) {
    return this.patch(`/api/Agencies/${AgencyId}`, { ...payload });
  }
  sendAgencyInvite(agentId) {
    return this.post('/api/Agencies/sendInvite', { agentId });
  }

  // Agents
  archiveAgent(agentId, archived) {
    return this.patch(`/api/Agents/${agentId}`, { archived });
  }
  deleteAgent(agentId) {
    return this.delete(`/api/Agents/${agentId}`);
  }
  createAgent(data) {
    return this.post('/api/Agents', data);
  }
  getAgent(where) {
    return this.get(filterizeAPIURL('Agents', { where }));
  }
  getAgentDetails(agentId) {
    return this.get(`/api/Agents/${agentId}`);
  }
  patchAgent(agentId, payload) {
    return this.patch(`/api/Agents/${agentId}`, { ...payload });
  }
  agentLogout() {
    return this.post('/api/Agents/logout');
  }
  userInfo() {
    if (this.axios.defaults.headers.common['Authorization']) {
      return this.get('/api/Info/whoAmI');
    }
    throw new Error('No token');
  }
  captureLead(fields) {
    return this.post('/api/Info/captureLead', { ...fields });
  }
  getClient(id) {
    return this.get(
      `/api/Clients/${id}?filter=${encodeURIComponent(
        JSON.stringify({
          include: [{ relation: 'agency' }, { relation: 'itineraries', scope: { include: ['payments'] } }, { relation: 'agent' }],
        }),
      )}`,
    );
  }
  clientInfo(clientId) {
    return this.get(filterizeAPIURL('Clients', { where: { id: clientId } }));
  }

  async getAuditTrailCount(where = {}) {
    return this.get(`/api/AuditTrail/count?where=${encodeURIComponent(JSON.stringify(where))}`);
  }

  async getClientUpdates(query = {}) {
    return this.get(filterizeAPIURL(`AuditTrail`, query));
  }

  async getClientNotificationsForAgent(agentId, filter = {}) {
    const where = { agentId };
    return this.get(
      filterizeAPIURL(`AuditTrail`, {
        where,
        ...filter,
        fields: ['clientId', 'itineraryId', 'agencyId', 'agentId', 'action', 'details', 'timestamp'],
        include: ['agency', 'agent', 'client', 'itinerary'],
        order: ['timestamp DESC', 'id DESC'],
      }),
    );
  }

  async getClientNotificationsForAgency(agencyId, filter = {}) {
    const where = { agencyId };
    return this.get(
      filterizeAPIURL('AuditTrail', {
        where,
        ...filter,
        fields: ['clientId', 'itineraryId', 'agencyId', 'agentId', 'action', 'details', 'timestamp'],
        include: ['agency', 'agent', 'client', 'itinerary'],
      }),
    );
  }

  getLodgeMappingPieces() {
    return this.get('/api/Info/getLodgeMappingPieces', {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
        return data;
      },
    });
  }
  getExchangeRates(currency = 'USD') {
    return this.get(`/api/Info/getExchangeRates?currency=${currency}`);
  }
  getRoomsBySupplier(supplierCodes) {
    return this.post('/api/Rooms/getBySupplier', {
      supplierCodes,
    });
  }
  getRoomsBySupplierWithDates(supplierCode, startDate, endDate) {
    return this.get(`/api/Rooms/getBySupplierWithDates?supplierCode=${supplierCode}&startDate=${startDate}&endDate=${endDate}`);
  }
  getItineraryActivities(id) {
    return this.get(`/api/Itineraries/${id}/activities`);
  }
  getActivities(supplierCodes, limit = 0, startDate, endDate) {
    return this.post('/api/Activities/getBySupplier', {
      supplierCodes,
      limit,
      startDate,
      endDate,
    });
  }
  getActivitiesByLocation(locationCode, limit = 0, includeImages, startDate, endDate) {
    return this.post('/api/Activities/getByLocation', {
      locationCode,
      limit,
      includeImages,
      startDate,
      endDate,
    });
  }
  getAvailability(supplierCodes, dateFrom, dateTo, disable3P = false, wait = false) {
    return this.post('/api/Availability/getBySupplier', {
      supplierCodes,
      startDate: dateFrom,
      endDate: dateTo,
      disable3P,
      wait,
    });
  }
  async getItineraryCostChanges(id) {
    const response = await this.get(`/api/Itineraries/${id}/cost-changes`);
    return response.data;
  }
  itineraryPatch(itineraryId, key, value) {
    return this.patch(`/api/Itineraries/${itineraryId}`, { [key]: value });
  }
  itineraryUpdate(itinerary) {
    return this.put(`/api/Itineraries/${itinerary.id}`, itinerary);
  }
  itineraryDelete(itineraryId) {
    log.debug(`itineraryDelete for id: ${itineraryId}`);
    return this.delete('/api/Itineraries/' + itineraryId);
  }
  // We don't use itinerary here but it needs to match the signature of the others for EditItinerary onNextAction
  itineraryQuote(itineraryId, _itinerary = null, sendNotify = true, requote = false) {
    log.debug(`WindowAPI GetQuote for id: ${itineraryId}`);
    return this.post('/api/WindowAPIs/GetQuote', {
      itineraryId,
      mock: false,
      sendNotify,
      requote,
    });
  }
  itineraryDuplicate(id) {
    return this.post(`/api/Itineraries/${id}/duplicate`);
  }
  // We don't use itinerary here but it needs to match the signature of the others for EditItinerary onNextAction
  itineraryCancel(itineraryId, _itinerary, waitForComplete = false) {
    log.debug(`WindowAPI cancelBooking for ${itineraryId}`);
    return this.post(`/api/WindowAPIs/CancelBooking`, { itineraryId, waitForComplete });
  }
  itineraryUpdateAndRequote(id, payload, sendNotify) {
    return this.post(`/api/Itineraries/${id}/update-requote`, {
      id,
      payload,
      sendNotify,
    });
  }
  itineraryGenerateInvoicesWithDepositRate(itineraryId, depositRate) {
    return this.post(`/api/Itineraries/${itineraryId}/generate-payments-with-deposit`, {
      depositRate,
    });
  }
  // @todo: EI1 legacy call
  itineraryInvoices(itineraryId, itinerary) {
    log.debug(`Generate Invoices for id: ${itineraryId}`);
    return this.post(`/api/Itineraries/${itinerary.id}/generatePayments`);
  }
  itineraryClearInvoices(itineraryId, itinerary) {
    log.debug(`Clear Invoices for id: ${itineraryId}`);
    return this.post(`/api/Itineraries/${itinerary.id}/clearPayments`);
  }
  itineraryConfirm(itineraryId) {
    log.debug(`WindowAPI ConfirmBooking for id: ${itineraryId}`);
    return this.post('/api/WindowAPIs/ConfirmQuote', {
      itineraryId,
      mock: false,
    });
  }
  itineraryRefresh(itineraryId) {
    log.debug(`WindowAPI RefreshQuote for id: ${itineraryId}`);
    return this.post('/api/WindowAPIs/RefreshQuote', {
      itineraryId,
      mock: false,
    });
  }
  itinerarySendConsultantEmail(itineraryId, conversation, message, conversationTitle) {
    log.debug(`WindowAPI sendConsultantEmail for id: ${itineraryId}`);
    return this.post('/api/WindowAPIs/sendConsultantEmail', {
      itineraryId,
      conversation,
      message,
      conversationTitle,
    });
  }
  async itineraryRestore(historyId) {
    const response = await this.post(`/api/ItineraryHistory/${historyId}/restore`);
    return response.data;
  }
  getItineraryHistory(id) {
    // @todo: for some reasont the fields filter is not working? maybe we need to add the fields explicitly to the model.json for this to work?
    return this.get(`/api/Itineraries/${id}/history?filter[fields][patch]=false&filter[fields][original]=false`);
  }
  getClientItineraries(clientId) {
    return this.get(
      filterizeAPIURL(`Clients/${clientId}`, {
        include: {
          relation: 'itineraries',
          scope: {
            include: ['messages', 'payments', 'video'],
          },
        },
      }),
    ).then(threadizeItineraryMessages);
  }
  getItinerariesCount(where) {
    return this.get('/api/Itineraries/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }
  getItineraryCampInfo(itineraryId) {
    return this.get(`/api/Itineraries/${itineraryId}/camp-info`);
  }
  getItineraryFromSavedItinerary(savedItineraryId, agencyId, agentId) {
    return this.get(`/api/SavedItineraries/getItineraryFrom?id=${savedItineraryId}&agencyId=${agencyId}&agentId=${agentId}`);
  }
  updateItineraryCurrency(id, currency) {
    return this.post(`/api/Itineraries/${id}/change-currency`, {
      currency,
    });
  }
  getFeatures() {
    return this.get('/api/Features');
  }

  getAuditAccommodation(supplierCode) {
    return this.get(`/api/Calendar/auditSupplier?supplierCode=${supplierCode}`);
  }
  getCamps(filter) {
    return this.get('/api/Camps' + (filter ? `?filter=${encodeURIComponent(JSON.stringify(filter))}` : ''));
  }
  getCampsCount(where) {
    return this.get('/api/Camps/count' + (where ? `?where=${encodeURIComponent(JSON.stringify(where))}` : ''));
  }
  getCamp(filter) {
    return this.get(`/api/Camps` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  getCampInfo(supplierCode) {
    return this.get(`/api/Camps/${supplierCode}/camp-info`);
  }
  setCamp(supplierCode, payload) {
    return this.post(`/api/Camps/upsertWithWhere?where=${JSON.stringify({ supplierCode })}`, payload);
  }
  getRegion(filter) {
    return this.get(`/api/Regions` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  setRegion(regionCode, payload) {
    return this.post(`/api/Regions/upsertWithWhere?where=${JSON.stringify({ regionCode })}`, payload);
  }
  getRegions(filter) {
    return this.get('/api/Regions' + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  getRegionsCount(where) {
    return this.get('/api/Regions/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }
  getCountry(filter) {
    return this.get(`/api/Countries` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  setCountry(countryCode, payload) {
    return this.post(`/api/Countries/upsertWithWhere?where=${JSON.stringify({ countryCode })}`, payload);
  }
  getCountries(filter) {
    return this.get('/api/Countries' + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  getCountriesCount(where) {
    return this.get('/api/Countries/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }
  getActivity(filter) {
    return this.get(`/api/Activities` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  setActivity(optionKey, payload) {
    return optionKey
      ? this.post(`/api/Activities/upsertWithWhere?where=${JSON.stringify({ optionKey })}`, payload)
      : this.post(`/api/Activities`, payload);
  }
  getActivityCarouselPhotos(id) {
    return this.get(`/api/Activities/${id}/carousel-photos`);
  }
  listActivities(filter) {
    return this.get('/api/Activities' + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  getActivitiesCount(where) {
    return this.get('/api/Activities/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }
  getItineraries(filter = {}) {
    return this.get(filterizeAPIURL('Itineraries', filter));
  }
  updateMessage(id, payload) {
    return this.patch(`/api/Messages/${id}`, payload);
  }
  updateMessages(where, payload) {
    return this.post(`/api/Messages/update?where=${encodeURIComponent(JSON.stringify(where))}`, payload);
  }
  getMessages(itineraryId) {
    return this.get(
      filterizeAPIURL(`Messages`, {
        where: { itineraryId },
      }),
    );
  }
  getMessagesForItinerary(itineraryId) {
    return this.get(
      filterizeAPIURL(`Messages`, {
        where: { itineraryId },
        include: 'agent',
      }),
    ).then(({ data }) => {
      // Group by conversation id
      const ret = buildThreads(data);
      return ret;
    });
  }
  uploadToS3(url, file, onProgress) {
    return axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: onProgress,
    });
  }
  async uploadFile(file, options = {}) {
    const { name: userFilename, size, type: mimeType } = file;
    try {
      const fileUpload = await this.post(`/api/MediaContent/`, {
        userFilename,
        size,
        mimeType,
        ...options,
      });
      return {
        data: fileUpload.data,
        file,
      };
    } catch (err) {
      // TODO: Should we feedback to user here?
      log.warn('error uploading file', err);
    }
  }
  uploadFiles(files) {
    return Promise.all(files.map(file => this.uploadFile(file)));
  }
  getFiles() {
    const filter = {
      where: {
        and: [{ clientId: { exists: false } }, { itineraryId: { exists: false } }],
      },
      include: ['collections', 'tags', 'categories', 'lightboxes'],
      order: 'id desc',
    };
    return this.get(`api/MediaContent?filter=${JSON.stringify(filter)}`);
  }
  getMediaFacets() {
    return this.get(`api/MediaContent/getFacetCounts`);
  }
  async getMediaContent(userId = null) {
    const filter = {
      where: { status: 'uploaded' },
      order: 'id DESC',
      include: ['tags', 'collections', 'categories', 'lightboxes'],
    };
    if (userId) {
      filter.where.createdBy = { like: String(userId) };
    }
    return await this.getData(`/MediaContent/?filter=${JSON.stringify(filter)}`);
  }
  relateItems(collectionType, id, relatedType, relatedId) {
    return this.put(`/api/${collectionType}/${id}/${relatedType}/rel/${relatedId}`);
  }
  patchMediaContent(mediaContentId, payload) {
    return this.patch(`/api/MediaContent/${mediaContentId}`, payload);
  }
  updateMediaContent(mediaContentId, payload) {
    return this.put(`/api/MediaContent/${mediaContentId}`, payload);
  }
  deleteMediaContent(mediaContentId) {
    return this.delete(`/api/MediaContent/${mediaContentId}`);
  }
  getTags() {
    return this.getData(`Tags`);
  }
  getCollections(params) {
    return this.getData(`Collections`, params ? { params } : {});
  }
  getLightbox(lightboxId) {
    return this.getData(filterizeURL(`Lightboxes/${lightboxId}`, { include: ['media'] }));
  }
  getLightboxes() {
    return this.getData(filterizeURL('Lightboxes', { include: ['media'] }));
  }
  async getLightboxesForFiles(files) {
    // Lightboxes that contain all of the passed in files
    if (!files.length) return [];
    const fileIds = [];
    const lightboxCollections = await Promise.all(
      files.map(file => {
        fileIds.push(file.id);
        return this.get(`api/MediaContent/${file.id}/lightboxes?filter=${JSON.stringify({ include: ['media'] })}`);
      }),
    );
    if (!lightboxCollections.length) return [];
    const keyedLightboxes = lightboxCollections.reduce((accumulator, { data: lightboxes }) => {
      for (const lb of lightboxes) {
        const mediaIds = lb.media.map(({ id }) => id);

        const hasAllFiles = fileIds.every(fileId => mediaIds.includes(fileId));
        if (hasAllFiles) accumulator[lb.id] || (accumulator[lb.id] = lb);
      }
      return accumulator;
    }, {});
    return Object.keys(keyedLightboxes).map(k => keyedLightboxes[k]);
  }
  createLightbox(label) {
    return this.post(`/api/Lightboxes`, { label });
  }
  async populateLightbox(id, files) {
    await Promise.all(files.map(file => this.put(`/api/Lightboxes/${id}/media/rel/${file.id}`)));
    return await this.getData(filterizeURL(`Lightboxes/${id}`, { include: ['media'] }));
  }
  async depopulateLightbox(id, files) {
    await Promise.all(files.map(file => this.delete(`/api/Lightboxes/${id}/media/rel/${file.id}`)));
    return await this.getData(filterizeURL(`Lightboxes/${id}`, { include: ['media'] }));
  }
  createTag(label) {
    return this.post(`/api/Tags`, { label });
  }
  async createTags(collection) {
    log.debug('createTags', collection);
    try {
      const items = await Promise.all(collection.map(item => this.createTag(item.label)));
      log.debug('items', items);
    } catch (err) {
      log.warn('error creating tags', err);
    }
  }
  getCollectionByType(type) {
    return this.getData(`/${type}`);
  }
  getMediaByCamp(supplierCode) {
    const filter = {
      where: {
        accommodation: supplierCode,
      },
    };
    return this.get(`/api/MediaContent?filter=${JSON.stringify(filter)}`);
  }
  createItemByType(type, attributes) {
    return this.postData(`/${type}`, attributes);
  }
  async createItemsByType(type, items) {
    try {
      let results = await Promise.all(
        items
          .map(item => {
            // TODO: Make sure we don't send an id I guess by taking it out
            const { id: _id, ...rest } = item;
            return this.createItemByType(type, rest);
          })
          .map(promise => promise.catch(Error)),
      );
      // silence failed creates
      results = results.filter(result => !(result instanceof Error));
      return results;
    } catch {
      log.warn('error creating items by type');
    }
  }
  mediaDeleteAssociations(id, type) {
    log.debug(`mediaDeleteAssociations ${id} ${type}`);
    return this.delete(`/api/MediaContent/${id}/${type}`);
  }
  async mediaAddAssociations(id, type, toAssociate) {
    log.debug(`mediaAddAssociations ${id} ${type} associations: `, toAssociate);
    const promises = [];
    map(toAssociate, association => {
      log.debug(` adding association: `, association);
      promises.push(this.put(`/api/MediaContent/${id}/${type}/rel/${association.id}`));
    });
    await Promise.all(promises);
    const filter = { include: ['tags', 'collections', 'categories'] };
    const newMedia = await this.get(`/api/MediaContent/${id}?filter=${JSON.stringify(filter)}`);
    return newMedia.data;
  }
  // await Api.updateCustomization(userContext.user.agency.id, { [name]: value });
  async updateCustomization(agencyId, customizations) {
    try {
      const results = await this.patch(`/api/Agencies/${agencyId}`, { ...customizations });
      return results.data.data;
    } catch (err) {
      log.warn('updateCustomization', err);
    }
  }
  async getCustomizations(agencyId) {
    try {
      const filter = { include: ['lightlogos', 'darklogos', 'featuredvideos', 'featuredphotos', 'featuredvideopreviews'] };
      const response = await this.get(`/api/Agencies/${agencyId}?filter=${JSON.stringify(filter)}`);
      const data = {
        ...response.data,
      };
      // coerce arrays to single entries
      const types = [
        ['featuredvideos', 'featuredVideo'],
        ['featuredvideopreviews', 'featuredVideoPreview'],
        ['featuredphotos', 'featuredPhoto'],
        ['darklogos', 'logoDark'],
        ['lightlogos', 'logoLight'],
      ];
      for (const type of types) {
        const [collection, target] = type;
        if (get(data, collection, []).length) {
          data[target] = data[collection][0];
        } else {
          data[target] = '';
        }
        delete data[collection];
      }
      return data;
    } catch (err) {
      log.warn('getCustomization', err);
    }
  }
  handleErrorMessage(error, defaultResponse = 'An unknown error occurred', callback) {
    let message;
    // Attempt to break out the individual messages
    const messages = get(error, 'response.data.error.details.messages');
    message = messages
      ? reduce(messages, (ret, msg) => `${ret} ${msg}`, defaultResponse + ':')
      : get(error, 'response.data.error.message', defaultResponse);
    callback(message, { variant: 'error' });
    return message;
  }
  updateAgency(agencyId, key, value) {
    let payload = {};
    set(payload, key, value);
    return this.patch(`/api/Agencies/${agencyId}`, payload);
  }
  updateAgent(agentId, key, value) {
    let payload = {};
    set(payload, key, value);
    return this.patch(`/api/Agents/${agentId}`, payload);
  }
  agentSetPassword(password) {
    return this.post('/api/Agents/set-password', { password });
  }
  createShareLink(clientId) {
    return this.post(`/api/Agents/create-share-link?clientId=${clientId}`);
  }
  createShareLinkForSavedItinerary(savedItineraryId, agencyId, agentId) {
    //return `${baseUri}/example/${savedItineraryId}/${agencyId}/${agentId}`; // No api call needed!
    return this.post(`/api/Agents/createShareLinkForSavedItinerary/${savedItineraryId}/${agencyId}/${agentId}`);
  }
  createSavedItineraryFromItinerary(itinerary, userContext, options) {
    const user = userContext.user;
    // The new itinerary should belong to this user's agency and agent if possible
    const agencyId = get(user, 'agency.id', itinerary.agencyId);
    const agentId = get(user, 'id', itinerary.agentId);
    return this.post(`/api/SavedItineraries/createSavedItineraryFromItinerary/${itinerary.id}/${agencyId}/${agentId}`, options);
  }
  createVideoLink(itineraryId) {
    return this.post(`/api/Agents/createVideoLink/${itineraryId}`);
  }
  createSavedItinerary(data) {
    return this.post('/api/SavedItineraries', data);
  }
  savedItinerariesGetQuote(itineraryShell, agencyId, agentId, clientId, sendNotify = true) {
    return this.post('/api/SavedItineraries/getQuote', { itineraryShell, agencyId, agentId, clientId, sendNotify });
  }
  deleteSavedItinerary(savedItineraryId) {
    return this.delete(`/api/SavedItineraries/${savedItineraryId}`);
  }
  updateSavedItinerary(savedItineraryId, updates) {
    return this.patch(`/api/SavedItineraries/${savedItineraryId}`, { ...updates });
  }
  getSavedItineraries({ limit } = { limit: 0 }) {
    return this.get(`/api/SavedItineraries/getSavedItineraries?limit=${limit}`);
  }
  getSharedLightbox(accessTokenId) {
    return this.get(
      filterizeAPIURL(`SharedLightboxes`, {
        where: { accessTokenId },
      }),
    );
  }
  createLightboxShareLink(lightboxId, hiResEnabled, watermarksEnabled) {
    log.debug('createLightboxShareLink', lightboxId, hiResEnabled, watermarksEnabled);
    return this.post(
      `/api/Agents/create-lightbox-share-link?lightboxId=${lightboxId}&hiResEnabled=${hiResEnabled}&watermarksEnabled=${watermarksEnabled}`,
    );
  }
  importBooking(clientId, agentId, bookingId, forceProd) {
    return this.post('/api/WindowAPIs/ImportBooking', {
      clientId,
      agentId,
      bookingId,
      forceProd,
    });
  }
  getPaymentsCount(where) {
    return this.get('/api/Payments/count' + (where ? `?where=${JSON.stringify(where)}` : ''));
  }
  getPayments(filter) {
    return this.get('/api/Payments' + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
  }
  updatePayments(itineraryId, payments, changes) {
    return this.post(`/api/Itineraries/${itineraryId}/updatePayments`, { payments, changes });
  }
  markAsPaid(itineraryId, paymentId, paymentData, send) {
    return this.post(`/api/Itineraries/${itineraryId}/markAsPaid`, { paymentId, paymentData, send });
  }
  async getCurrencies() {
    return axios.get('https://openexchangerates.org/api/currencies.json');
  }

  async getCampRegions(supplierCodes) {
    const campRegions = await this.getCamps({
      where: { supplierCode: { inq: supplierCodes } },
    });
    const supplierCodesReduce = supplierCodes.reduce((acc, supplierCode) => {
      // Order matters so order by supplierCodes.
      acc.push([supplierCode, find(campRegions.data, { supplierCode })?.countryRegionCode]);
      return acc;
    }, []);
    return supplierCodesReduce;
  }
  async getCampTransitPoints(supplierCodes) {
    const campRegions = await this.getCampRegions(supplierCodes);
    const transitPointsBySupplierCode = await this.getRegionTransitPoints(campRegions);
    return transitPointsBySupplierCode;
  }
  async getRegionTransitPoints(campRegionCodes) {
    // TODO: This is pretty complicated. Maybe we should move this to the backend and cache it?
    // https://github.com/itrvl/itrvl/issues/1343
    const countryRegionCodes = campRegionCodes.reduce((acc, [_supplierCode, regionCode]) => {
      acc.push(regionCode);
      return acc;
    }, []);

    const regionsFilter = {
      where: { countryRegionCode: { inq: countryRegionCodes } },
      fields: { countryRegionCode: true, transitPoints: true, preferredTransitPoint: true, countryCode: true },
    };

    const { data: regionsResults } = await this.get(`/api/Regions?filter=${JSON.stringify(regionsFilter)}`);

    // no need to uniq this.. extra op
    const { transitPointRegionCodeMap, transitPointCodes /*, countries*/ } = regionsResults.reduce(
      (acc, region) => {
        let transitPoints = [];
        if (region.preferredTransitPoint) {
          transitPoints.push(region.preferredTransitPoint);
        }
        transitPoints = transitPoints.concat(region.transitPoints);
        acc.transitPointCodes = acc.transitPointCodes.concat(transitPoints);
        // acc.countries.push(region.countryCode);

        // we need a way to map transitPoints[] and preferredTransitPoint back to it's orignal camps
        acc.transitPointRegionCodeMap = {
          ...acc.transitPointRegionCodeMap,
          ...transitPoints.reduce((acc, transitPointCode) => {
            acc[transitPointCode] = region.countryRegionCode;
            return acc;
          }, {}),
        };

        return acc;
      },
      {
        transitPointRegionCodeMap: {},
        transitPointCodes: [],
        countries: [],
      },
    );

    const transitPointsFilter = {
      where: {
        and: [{ or: [{ _id: { $in: transitPointCodes } }, { entryExitNode: true }] }, { disabled: { neq: true } }],
      },
      fields: { name: true, transitPointCode: true, entryExitNode: true, countryRegionCode: true, countryCode: true },
      order: 'name',
    };
    const { data: transitPointResults } = await this.get(`/api/TransitPoints?filter=${JSON.stringify(transitPointsFilter)}`);

    // @todo: we need to add:
    // if(transitPoints.length === 0) {
    //    // add in country transitPoints here...
    // }

    const collectedTransitPoints = transitPointResults.reduce(
      (acc, transitPoint) => {
        if (
          transitPoint.transitPointCode in transitPointRegionCodeMap &&
          get(transitPointRegionCodeMap, transitPoint.transitPointCode) !== undefined
        ) {
          acc[transitPointRegionCodeMap[transitPoint.transitPointCode]].push(transitPoint);
        }
        if (!acc[transitPoint.countryRegionCode]) {
          acc[transitPoint.countryRegionCode] = [];
        }
        if (!acc[transitPoint.countryRegionCode].includes(transitPoint)) {
          acc[transitPoint.countryRegionCode].push(transitPoint);
        }
        if (transitPoint.entryExitNode === true && transitPoint.transitPointCode in transitPointRegionCodeMap) {
          acc.allEntryExitNodes.push(transitPoint);
        }
        return acc;
      },
      {
        allEntryExitNodes: [],
        ...campRegionCodes.reduce((acc, [_, regionCode]) => {
          acc[regionCode] = [];
          return acc;
        }, {}),
      },
    );

    // data structure
    // {
    //   entry,
    //   midpoints,
    //   exit,
    // }
    //
    // entry => first stay region nodes + all entryExitNode === true
    // midpoint key places => smash up of both region nodes
    // exit => last stay region nodes + all entryExitNodes === true

    const defaultStructure = {
      entry: [...collectedTransitPoints.allEntryExitNodes],
      exit: [...collectedTransitPoints.allEntryExitNodes],
    };

    const transitPointsByType = campRegionCodes.reduce((acc, [supplierCode, regionCode], index) => {
      let collection = [...collectedTransitPoints[regionCode]];

      // copy first regions points into "entry"
      if (index === 0) {
        acc.entry = acc.entry.concat(collection);
      }

      // copy last regions points in to "exit"
      if (index === campRegionCodes.length - 1) {
        acc.exit = acc.exit.concat(collection);
      }

      // if we have a neighboring campRegionCode, then we need to populate a mashup of both regions transit points
      if (campRegionCodes[index + 1]) {
        // if regionCodes don't match, we need to fold them in together
        const nextRegionCode = campRegionCodes[index + 1][1];
        if (regionCode !== nextRegionCode) {
          collection = collection.concat(collectedTransitPoints[nextRegionCode]);
          collection = orderBy(uniq(collection), 'name');
        }
      }
      acc[supplierCode] = collection;
      return acc;
    }, defaultStructure);
    // dedupe and sort, meh
    transitPointsByType.entry = orderBy(uniq(transitPointsByType.entry), 'name');
    transitPointsByType.exit = orderBy(uniq(transitPointsByType.exit), 'name');

    return transitPointsByType;
  }

  async getFeaturedImagesForItinerary(id) {
    const { data: featuredImages } = await this.get(`/api/Itineraries/getFeaturedImagesForItinerary/${id}`);
    return featuredImages;
  }

  async swapAccommodation(itineraryId, { currentSupplierCode, sequenceNumber, newSupplierCode, accommodationRooms, nights }) {
    const { data } = await this.post('/api/WindowAPIs/swapAccommodation', {
      itineraryId,
      currentSupplierCode,
      sequenceNumber,
      newSupplierCode,
      accommodationRooms,
      nights,
    });
    return data;
  }

  async addAccommodationDays(itineraryId, newNumberOfNights, moveDatesDown, ffKey) {
    const { data } = await this.post('/api/WindowAPIs/addAccommodationDays', {
      itineraryId,
      newNumberOfNights,
      moveDatesDown,
      ffKey,
    });
    return data;
  }

  async addAccommodationToStartEnd(itineraryId, supplierCode, accommodationRooms, nights, atStart) {
    const { data } = await this.post('/api/WindowAPIs/addAccommodationToStartEnd', {
      itineraryId,
      supplierCode,
      accommodationRooms,
      nights,
      atStart,
    });
    return data;
  }

  // party-groups
  async getItineraryParties(itineraryId, filter) {
    const { data } = await this.get(`/api/Itineraries/${itineraryId}/parties` + (filter ? `?filter=${JSON.stringify(filter)}` : ''));
    return data;
  }

  async deleteItineraryParty(itineraryId, partyId) {
    return this.delete(`/api/Itineraries/${itineraryId}/parties/${partyId}`);
  }

  async getPartyGroup(partyId) {
    return this.getData(filterizeURL(`PartyGroups/${partyId}`, { include: ['travelers'] }));
  }

  async updatePartyGroup(partyId, payload) {
    const { data } = await this.patch(`/api/PartyGroups/${partyId}`, payload);
    return data;
  }

  async createItineraryParty(itineraryId, payload) {
    const { data } = await this.post(`/api/Itineraries/${itineraryId}/parties`, payload);
    return data;
  }

  // travelers
  async updateTraveler(id, payload) {
    const { data } = await this.patch(`/api/Travelers/${id}`, payload);
    return data;
  }

  async deleteTraveler(id) {
    const { data } = await this.delete(`/api/Travelers/${id}`);
    return data;
  }

  async checkClientEmail(email, agencyId) {
    const { data } = await this.post(`/api/Clients/check-email/`, { email, agencyId });
    return data;
  }

  async search({ term, limit }) {
    const { data } = await this.get(`/api/Info/search?term=${term}&limit=${limit}`);
    return data;
  }

  // IB2
  async calendarBySupplier(supplierCode, startDate, endDate, adults, childrenAges, rooms, wait = true, options = {}) {
    const { signal } = options;
    const { data } = await this.get('/api/Calendar/bySupplier', {
      params: {
        supplierCode,
        startDate,
        endDate,
        adults,
        childrenAges,
        rooms,
        wait,
      },
      signal,
    });
    return data;
  }

  async getTransfers(payload) {
    const { data } = await this.get(
      `/api/WindowAPIs/getTransfers?codeFrom=${payload?.codeFrom}&typeFrom=${payload?.typeFrom}&codeTo=${payload?.codeTo}&typeTo=${payload?.typeTo}`,
    );
    return data;
  }

  async getTransfersOrig(payload) {
    const { data } = await this.get(
      `/api/WindowAPIs/getTransfersOrig?codeFrom=${payload?.codeFrom ?? ''}&typeFrom=${payload?.typeFrom ?? ''}&codeTo=${payload?.codeTo ??
        ''}&typeTo=${payload?.typeTo ?? ''}`,
    );
    return [].concat(data?.response?.interConnectResponse?.interConnect);
  }
}
