const Point = ({ color }) => (
  <svg viewBox="0 0 24 25">
    <path
      d="M14.8 10.3c0 1.6-1.3 2.8-2.8 2.8-1.6 0-2.8-1.2-2.8-2.8 0-1.5 1.2-2.8 2.8-2.8 1.5 0 2.8 1.2 2.8 2.8zM12 12c.9 0 1.7-.7 1.7-1.7 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7 0 .9.8 1.7 1.7 1.7zm6.8-1.7c0 3.1-4.1 8.5-5.9 10.8-.4.5-1.2.5-1.7 0-1.8-2.3-5.9-7.7-5.9-10.8 0-3.7 3-6.8 6.8-6.8 3.6 0 6.7 3 6.7 6.8zM12 4.6c-3.1 0-5.6 2.5-5.6 5.6 0 .6.2 1.3.6 2.2.4.9.9 1.9 1.5 2.9 1.2 1.9 2.6 3.8 3.5 4.9.9-1.1 2.3-3 3.5-4.9.6-1 1.2-2 1.5-2.9.4-.9.6-1.7.6-2.2 0-3-2.5-5.6-5.6-5.6z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Point;
