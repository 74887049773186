import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import Loader from './Loader';
import ListCard from './ListCard';
import InfiniteLoader from './InfiniteLoader';
import { useBuilderStore } from './store';
import { useAccommodationsListQuery, useSearchQuery } from './queries';

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 20,
      alignItems: 'stretch',
    },
    loader: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
    },
  },
  {
    name: 'AccommodationsList',
  },
);

export const ownAccommodationProps = {
  campFeatures: [],
  campInfo:
    'When adding an own-arranged accommodation, you will need to provide a title for the accommodation and select location details. Once you have generated a quote, you can customize the accommodation details in Edit in Presentation Editor.',
  label: 'Own Accommodation',
  campName: 'Own Accommodation',
  consultantInteractionRequired: false,
  country: 'TBD',
  description:
    'When adding an own-arranged accommodation, you will need to provide a title for the accommodation and select location details. Once you have generated a quote, you can customize the accommodation details in Edit in Presentation Editor.',
  dmcArrangedOnly: false,
  online: false,
  regionName: 'TBD',
  supplierCode: 'OWN',
  restOfWorld: false,
};

const AccommodationsList = () => {
  const classes = useStyles();
  const term = useBuilderStore(state => state.ui.accommodations.term);

  const accommodationsListQuery = useAccommodationsListQuery();
  const searchQuery = useSearchQuery();
  const [isLoading, data, hasNextPage, fetchNextPage] = ['isLoading', 'data', 'hasNextPage', 'fetchNextPage'].map(key =>
    term ? searchQuery[key] : accommodationsListQuery[key],
  );

  const termMatchesOwnAccommodation = ownAccommodationProps.campName.toLowerCase().includes(term.toLowerCase());

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const viewMode = useBuilderStore(state => state.ui.viewMode);

  return (
    <div data-test-id="accommodations-list" className={clsx(classes.root, classes[viewMode])} /*ref={parent}*/>
      {isLoading ? (
        <Loader text="Finding Accommodations" />
      ) : (
        <>
          {((term && termMatchesOwnAccommodation) || data.pages[0].length === 0) && (
            <ListCard item={ownAccommodationProps} description={ownAccommodationProps.description} {...ownAccommodationProps} />
          )}
          {data.pages.map((camps, i) => {
            return (
              <React.Fragment key={i}>
                {camps.map(camp => (
                  <ListCard key={camp.supplierCode} item={camp} {...camp} label={camp.campName} description={camp.campInfo} />
                ))}
              </React.Fragment>
            );
          })}
          {hasNextPage && <InfiniteLoader ref={ref} />}
        </>
      )}
    </div>
  );
};

export default AccommodationsList;
