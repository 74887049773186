import React from 'react';
import { Redirect } from 'react-router-dom';

import DetailsContent from './DetailsContent';

import { useBuilderStore } from './store';
import OwnDetailsContent from './OwnDetailsContent';

import logger from 'itrvl-logger';
import { OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';
const log = logger(__filename);
log.trace(__filename);

const Details = () => {
  const editId = useBuilderStore(state => state.ui.accommodations.edit);
  const segment = useBuilderStore(state => state.data.segmentsById?.[editId]);
  // if we visit /details without any data, redirect back
  if (!segment) {
    return <Redirect to="/itinerary-builder" />;
  }
  return (
    <>
      return{' '}
      <>{segment.supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE ? <OwnDetailsContent id={editId} /> : <DetailsContent id={editId} />}</>;{' '}
    </>
  );
};

export default React.memo(Details);
