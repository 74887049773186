import React from 'react';
import Fade from '@mui/material/Fade';

import { useBuilderStore } from './store';
import { isUndefined } from 'lodash';
import CardListModeButtonGroup from 'components/v2/CardListModeButtonGroup';

const ViewSelector = () => {
  const date = useBuilderStore(state => state.data.date);
  const showAvailability = !isUndefined(date);
  const viewMode = useBuilderStore(state => state.ui.viewMode);
  const setViewMode = useBuilderStore(state => state.actions.accommodations.setViewMode);
  const onChange = (_event, mode) => setViewMode(mode);
  return (
    <Fade in={showAvailability}>
      <div>
        <CardListModeButtonGroup value={viewMode} onChange={onChange} />
      </div>
    </Fade>
  );
};

export default ViewSelector;
