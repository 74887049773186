import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { get, map, filter } from 'lodash';
import { routes } from '../routes';
import { blocks } from './SiteMenu.utils';

import { ReactComponent as ItrvlLogo } from 'common/assets/itrvl_logo--all-white.svg';
import { ReactComponent as CloseMenu } from 'common/assets/close-menu.svg';

import { UserContext } from 'common/context/UserContext';

import { Drawer, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Typography } from '@mui/material';

import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import NewChip from './NewChip';
import BetaChip from './BetaChip';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

// TODO: potentially share styling with Client-SiteMenu <- please don't
const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.palette.primary.light} ${theme.palette.primary.main} `,
    },

    menu: {
      width: '30vw',
      maxWidth: 500,
      minWidth: 350,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12)',
      //   maxWidth: 500,
      overflowY: 'auto',

      '& .muitrvl-MuiButtonBase-root': {
        borderRadius: 10,
        marginBottom: 5,
        '&.selected': {
          pointerEvents: 'none',
        },
        '&:hover, &.selected': {
          backgroundColor: theme.palette.primary.light,
        },
        '& .MuiListItemIcon-root': {
          minWidth: 35,
        },
      },
    },
    menuHeader: {
      display: 'flex',
    },
    logo: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 20,
    },
    closeMenu: {
      alignSelf: 'start',
      justifySelf: 'flex-start',
      marginRight: 10,
      cursor: 'pointer',
      padding: 25,
    },
    itemText: {
      color: '#fff',
    },
    itemTextNoIndent: {
      paddingLeft: '0px',
    },
    avatar: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    menuIcon: {
      color: '#fff',
      width: '20px',
      fontSize: '2em',
    },
    menuBlock: {
      marginBottom: 25,
    },
    divider: {
      fontWeight: 600,
      color: 'white',
    },
  }),
  {
    name: 'SideBar',
  },
);

const MenuLink = ({ route, classes, selected, onClose }) => {
  const { Icon, icon, name, path } = route;
  const to = Array.isArray(path) ? path[0] : path;
  const routeIcon = Icon ? <Icon className={classes.menuIcon} /> : <ItrvlIcon name={icon} className={classes.menuIcon} />;
  return (
    <ListItem
      button
      to={to}
      component={Link}
      className={clsx(selected && 'selected')}
      onClick={onClose}
      id={`agent_${route.name.toLowerCase().replace(/ /g, '-')}_list-item`}
    >
      <ListItemIcon>{routeIcon}</ListItemIcon>
      <ListItemText className={classes.itemTextNoIndent} classes={{ primary: classes.itemText }} primary={name} />
      {route?.new === true && (
        <ListItemSecondaryAction>
          <NewChip />
        </ListItemSecondaryAction>
      )}
      {route?.beta === true && (
        <ListItemSecondaryAction>
          <BetaChip />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const SiteMenu = ({ open, onClose, logout }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const name = get(user, 'firstName', '');
  const email = get(user, 'email');
  return (
    <Drawer classes={{ paper: classes.drawer }} open={open} onClose={onClose} data-test-id="site-menu">
      <div className={classes.root}>
        <div className={classes.menu} data-test-id="site-menu-list">
          <div className={classes.menuHeader}>
            <div className={classes.logo}>
              <ItrvlLogo alt="itrvl" />
            </div>
            <div className={classes.closeMenu} onClick={onClose} data-test-id="site-menu-close">
              <CloseMenu className={classes.icon} />
            </div>
          </div>
          <List>
            <ListItem className={classes.avatar}>
              <ListItemText
                classes={{ primary: classes.itemText, secondary: classes.itemText }}
                primary={`Welcome, ${name}`}
                secondary={email}
              />
            </ListItem>

            {map(blocks, (block, key) => {
              if (block.test(user)) {
                return (
                  <div key={key} className={classes.menuBlock}>
                    <Typography variant="body" className={classes.divider}>
                      {block.label}
                    </Typography>
                    {map(
                      filter(routes, block.filter),
                      route =>
                        (!route.flag || (route.flag && route.flag(user))) && (
                          <MenuLink
                            key={route.name}
                            selected={
                              Array.isArray(route.path)
                                ? route.path.find(p => p.pathname === route.path)
                                : !!(location.pathname === route.path || location.pathname === route.authenticatedRedirect)
                            }
                            user={user}
                            route={route}
                            classes={classes}
                            onClose={onClose}
                          />
                        ),
                    )}
                  </div>
                );
              }
              return null;
            })}

            {/* TODO: Add a /logout route that does the logout so this can be a Link */}
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ItrvlIcon name="logOut" className={classes.menuIcon} />
              </ListItemIcon>
              <ListItemText className={classes.itemTextNoIndent} classes={{ primary: classes.itemText }} primary={'Log Out'} />
            </ListItem>
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default SiteMenu;
