import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useIntersection } from 'react-use';
import { CircularProgress } from '@mui/material';
import AvailabilityCalendar from './AvailabilityCalendar';

const useStyles = makeStyles({
  calendar: {
    backgroundColor: '#fff',
    height: '100%',
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/**
 * Wrapper to control the visibility of the AvailabilityCalendar based on an IntersectionObserver
 * When this was done inline, the initial paint was not firing. Moving it to it's own component fixed this.
 * Probably due to a stale ref
 * @returns
 */
const AvailabilityCalendarVisibilityWrapper = ({ supplierCode, item }) => {
  const classes = useStyles();
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: document.querySelector('[class^=routes-root]'),
    rootMargin: '0px 0px 100px 0px',
    threshold: 1,
  });
  return (
    <div className={classes.calendar} ref={intersectionRef}>
      {intersection && intersection.intersectionRatio === 0 && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      {intersection && intersection.intersectionRatio > 0 && (
        <AvailabilityCalendar data={item} supplierCode={supplierCode} enabled={true} />
      )}
    </div>
  );
};

export default AvailabilityCalendarVisibilityWrapper;
