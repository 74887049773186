import { get, map, reduce, filter, assign, isEmpty } from 'lodash';
import { buildURL } from 'react-imgix';
import { useImgix } from 'common/components/Image';
import { isImage } from 'common/helpers/files';
import { ReactComponent as BARG } from 'common/components/Icons/Features/BARG.svg';
import { ReactComponent as BOAT } from 'common/components/Icons/Features/BOAT.svg';
import { ReactComponent as CANO } from 'common/components/Icons/Features/CANO.svg';
import { ReactComponent as CUEX } from 'common/components/Icons/Features/CUEX.svg';
import { ReactComponent as ELIN } from 'common/components/Icons/Features/ELIN.svg';
import { ReactComponent as FAMI } from 'common/components/Icons/Features/FAMI.svg';
import { ReactComponent as FISH } from 'common/components/Icons/Features/FISH.svg';
import { ReactComponent as GADR } from 'common/components/Icons/Features/GADR.svg';
import { ReactComponent as GOTR } from 'common/components/Icons/Features/GOTR.svg';
import { ReactComponent as GUWA } from 'common/components/Icons/Features/GUWA.svg';
import { ReactComponent as GYMN } from 'common/components/Icons/Features/GYMN.svg';
import { ReactComponent as HABA } from 'common/components/Icons/Features/HABA.svg';
import { ReactComponent as HIDE } from 'common/components/Icons/Features/HIDE.svg';
import { ReactComponent as MATR } from 'common/components/Icons/Features/MATR.svg';
import { ReactComponent as MOKO } from 'common/components/Icons/Features/MOKO.svg';
import { ReactComponent as NIDR } from 'common/components/Icons/Features/NIDR.svg';
import { ReactComponent as QUBI } from 'common/components/Icons/Features/QUBI.svg';
import { ReactComponent as RHTR } from 'common/components/Icons/Features/RHTR.svg';
import { ReactComponent as SPTR } from 'common/components/Icons/Features/SPTR.svg';
import { ReactComponent as STBE } from 'common/components/Icons/Features/STBE.svg';
import { ReactComponent as SWPO } from 'common/components/Icons/Features/SWPO.svg';
import { ReactComponent as WIFI } from 'common/components/Icons/Features/WIFI.svg';
import { ReactComponent as PRPO } from 'common/components/Icons/Features/PRPO.svg';
import { ReactComponent as HEFL } from 'common/components/Icons/Features/HEFL.svg';
import { ReactComponent as DISA } from 'common/components/Icons/Features/DISA.svg';
import { ReactComponent as WINE } from 'common/components/Icons/Features/WINE.svg';
import { ReactComponent as TRTL } from 'common/components/Icons/Features/TRTL.svg';
import { ReactComponent as OUSH } from 'common/components/Icons/Features/OUSH.svg';
import { ReactComponent as HIKE } from 'common/components/Icons/Features/HIKE.svg';
import { ReactComponent as JETS } from 'common/components/Icons/Features/JETS.svg';
import { ReactComponent as SCUB } from 'common/components/Icons/Features/SCUB.svg';
import { ReactComponent as SURF } from 'common/components/Icons/Features/SURF.svg';
import { ReactComponent as SCFL } from 'common/components/Icons/Features/SCFL.svg';
import { ReactComponent as HORS } from 'common/components/Icons/Features/HORS.svg';
import { ReactComponent as SNRK } from 'common/components/Icons/Features/SNRK.svg';
import { ReactComponent as WHAL } from 'common/components/Icons/Features/WHAL.svg';
import { ReactComponent as GOLF } from 'common/components/Icons/Features/GOLF.svg';
import { ReactComponent as TENN } from 'common/components/Icons/Features/TENN.svg';
import { ReactComponent as CYCL } from 'common/components/Icons/Features/CYCL.svg';
import { ReactComponent as BIRD } from 'common/components/Icons/Features/BIRD.svg';
import { ReactComponent as AIRC } from 'common/components/Icons/Features/AIRC.svg';
import { ReactComponent as KIDS } from 'common/components/Icons/Features/KIDS.svg';
import { ReactComponent as LAUA } from 'common/components/Icons/Features/LAUA.svg';
import { ReactComponent as LAUN } from 'common/components/Icons/Features/LAUN.svg';
import { ReactComponent as PWIF } from 'common/components/Icons/Features/PWIF.svg';
import { ReactComponent as RMSV } from 'common/components/Icons/Features/RMSV.svg';
import { ReactComponent as JACZ } from 'common/components/Icons/Features/JACZ.svg';
import logger from 'itrvl-logger';

const log = logger(__filename);

const ImageCDN = process.env.REACT_APP_IMAGE_CDN;
const VideoCDN = process.env.REACT_APP_VIDEO_CDN;

const heroForResolutionFromLodgeList = (lodgeList, supplierCode, width) => {
  // console.log(`heroForResultionFromLodgeList: ${supplierCode} ${width}`);
  const heroMedia = get(lodgeList, supplierCode);
  // console.log(`heroMedia: `, JSON.stringify(heroMedia, null, 2));
  if (!heroMedia || !heroMedia.hero || isEmpty(heroMedia.hero)) {
    return `https://via.placeholder.com/${width}`;
  }
  return cdnUri(heroMedia.hero, { width: width });
};

const heroForResolution = (supplierCode, width) => {
  if (supplierCode === undefined) {
    return `https://via.placeholder.com/${width}`;
  }
  if (width <= 45) {
    return `${ImageCDN}/${supplierCode}/hero_45.jpg`;
  } else if (width <= 75) {
    return `${ImageCDN}/${supplierCode}/hero_75.jpg`;
  } else if (width <= 150) {
    return `${ImageCDN}/${supplierCode}/hero_150.jpg`;
  } else if (width <= 300) {
    return `${ImageCDN}/${supplierCode}/hero_300.jpg`;
  } else {
    return `${ImageCDN}/${supplierCode}/hero.jpg`;
  }
};

const getIconForFeature = feature => {
  let icon;

  switch (feature) {
    case 'BARG':
      icon = BARG;
      break;
    case 'BOAT':
      icon = BOAT;
      break;
    case 'CANO':
      icon = CANO;
      break;
    case 'CUEX':
      icon = CUEX;
      break;
    case 'ELIN':
      icon = ELIN;
      break;
    case 'FAMI':
      icon = FAMI;
      break;
    case 'FISH':
      icon = FISH;
      break;
    case 'GADR':
      icon = GADR;
      break;
    case 'GOTR':
      icon = GOTR;
      break;
    case 'GUWA':
      icon = GUWA;
      break;
    case 'GYMN':
      icon = GYMN;
      break;
    case 'HABA':
      icon = HABA;
      break;
    case 'HIDE':
      icon = HIDE;
      break;
    case 'MATR':
      icon = MATR;
      break;
    case 'MOKO':
      icon = MOKO;
      break;
    case 'NIDR':
      icon = NIDR;
      break;
    case 'QUBI':
      icon = QUBI;
      break;
    case 'RHTR':
      icon = RHTR;
      break;
    case 'SPTR':
      icon = SPTR;
      break;
    case 'STBE':
      icon = STBE;
      break;
    case 'SWPO':
      icon = SWPO;
      break;
    case 'WIFI':
      icon = WIFI;
      break;
    case 'PRPO':
      icon = PRPO;
      break;
    case 'HEFL':
      icon = HEFL;
      break;
    case 'DISA':
      icon = DISA;
      break;
    case 'WINE':
      icon = WINE;
      break;
    case 'TRTL':
      icon = TRTL;
      break;
    case 'OUSH':
      icon = OUSH;
      break;
    case 'HIKE':
      icon = HIKE;
      break;
    case 'JETS':
      icon = JETS;
      break;
    case 'SCUB':
      icon = SCUB;
      break;
    case 'SURF':
      icon = SURF;
      break;
    case 'SCFL':
      icon = SCFL;
      break;
    case 'HORS':
      icon = HORS;
      break;
    case 'SNRK':
      icon = SNRK;
      break;
    case 'WHAL':
      icon = WHAL;
      break;
    case 'GOLF':
      icon = GOLF;
      break;
    case 'TENN':
      icon = TENN;
      break;
    case 'CYCL':
      icon = CYCL;
      break;
    case 'BIRD':
      icon = BIRD;
      break;
    case 'AIRC':
      icon = AIRC;
      break;
    case 'KIDS':
      icon = KIDS;
      break;
    case 'LAUA':
      icon = LAUA;
      break;
    case 'LAUN':
      icon = LAUN;
      break;
    case 'PWIF':
      icon = PWIF;
      break;
    case 'RMSV':
      icon = RMSV;
      break;
    case 'JACZ':
      icon = JACZ;
      break;
    default:
      log.debug("couldn't find icon for feature: ", feature);
      icon = BARG;
      break;
  }
  return icon;
};
const heroForItineraryFromLodgeList = (lodgeList, itinerary, width) => {
  let selectedSegment = {};
  const featuredImageData = get(itinerary, 'itinerary.params.customizations.featuredImage', null);
  const featuredImage = featuredImageData ? cdnUri(featuredImageData, { width }) : null;
  if (!featuredImage && itinerary && itinerary.itinerary && itinerary.itinerary.segments) {
    // Take the second segment if more than one since first segment is likely to be a hotel which may not have a superior hero
    const staySegmentCount = filter(itinerary.itinerary.segments, { type: 'stay' }).length;
    let segmentsToSkip = staySegmentCount > 1 ? 1 : 0;
    selectedSegment = reduce(
      itinerary.itinerary.segments,
      (result, segment) => {
        //        log.debug(`got segment: `, segment);
        if (segment.type && segment.type === 'stay' && !result.supplierCode) {
          if (segmentsToSkip > 0) {
            segmentsToSkip--;
            return result;
          }

          result = segment;
        }
        return result;
      },
      {},
    );
  }
  //  log.debug(`selected segment, `, selectedSegment);
  const heroImage = heroForResolutionFromLodgeList(lodgeList, selectedSegment.supplierCode, width);
  return featuredImage || heroImage;
};

const cdnUri = (media, params = {}) => {
  let path = media.s3Key;
  if (useImgix) {
    path = media.videos ? media.thumbnails['1920'] : media.s3Key;
    const imgixParams = assign({ fit: 'crop', crop: 'faces' }, params);
    return buildURL(`${ImageCDN}/${encodeURI(path)}`, imgixParams);
  } else {
    if (params && params.width) {
      path = media.thumbnails[params.width];
    }
  }
  return `${ImageCDN}/${path}`;
};

const cdnUrl = (originalUrl, params = {}) => {
  if (!originalUrl) return;
  let url = new URL(decodeURI(originalUrl));
  let pathname = decodeURI(url.pathname.slice(1));
  return cdnUri({ s3Key: pathname }, params);
};
const placeholderUrl = cdnUri({ s3Key: 'media-default.jpg' });

const resizeImgixUrl = (fromUrl, newWidth) => {
  if (!String(fromUrl).includes('imgix.net')) {
    return fromUrl;
  }
  const url = new URL(fromUrl);
  url.searchParams.set('w', newWidth);
  return url.toString();
};

const mediaContentToVideoUrl = media => {
  if (media === undefined) {
    return placeholderUrl;
  }
  return `${VideoCDN}/${media.s3Key}`;
};

const itineraryToPosterUrl = (itinerary, options = {}) => {
  const image = { s3Key: get(itinerary, 'headerImage') };
  if (options.alt !== undefined && !image) {
    return options.alt;
  }
  return mediaContentToPosterUrl(image);
};

const getPosterMedia = itinerary => {
  const posterMedia = itineraryToPosterUrl(itinerary, { itineraryItinerary: true, alt: false });
  return posterMedia || `${ImageCDN}/assembled_${itinerary?.id}-1920.0000004.jpg?version=${itinerary?.assembledVersion}`;
};

const mediaContentToPosterUrl = media => {
  if (media === undefined) {
    return placeholderUrl;
  }
  return cdnUri(media);
};

const mediaContentToUrl = (size, media, params) => {
  if (useImgix || !media.thumbnails) {
    return cdnUri(media, params);
  } else {
    switch (size) {
      case 450:
        return cdnUri(media, { width: 450 });
      default:
        log.warn('Unexpected size: ', size);
      // intentional fall through
      case 900:
        return cdnUri(media, { width: 900 });
    }
  }
};

const singleMediaContentToUrl = media => {
  if (media === undefined || media.length === 0) {
    return placeholderUrl;
  } else {
    return Array.isArray(media) ? mediaContentToUrl(450, media[0]) : mediaContentToUrl(450, media);
  }
};

const safeSingleMediaContentToUrl = singleMediaContent => {
  if (singleMediaContent) {
    return cdnUri(singleMediaContent || '');
  }
};

const singleMediaContentToPreview = (media, params) => {
  if (media === undefined || media.length === 0) {
    return {
      type: 'image',
      url: placeholderUrl,
      description: '',
    };
  } else {
    const thisMedia = Array.isArray(media) ? media[0] : media;
    return isImage(thisMedia.mimeType)
      ? {
          type: 'image',
          url: mediaContentToUrl(450, thisMedia, params),
          description: thisMedia.description || '',
        }
      : {
          type: 'video',
          url: mediaContentToUrl(900, thisMedia, params),
          videos: thisMedia.videos,
        };
  }
};

const manyMediaContentToTypes = media => media.map(item => singleMediaContentToPreview(item));

const manyMediaContentToUrls = (media, minItems = 4) => {
  let urls = [];
  if (media === undefined || media.length === 0) {
    while (urls.length < minItems) {
      urls.push(placeholderUrl);
    }
  } else {
    urls = map(media, value => mediaContentToUrl(450, value));
    while (urls.length < minItems) {
      urls.push(placeholderUrl);
    }
  }
  return urls;
};

const fetchMediaContentImage = async mediaContent => {
  const blob = await fetch(singleMediaContentToUrl(mediaContent)).then(r => r.blob());
  const dataUrl = await new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
  return dataUrl;
};

const getStaticMediaURL = (s3Key, imgixParams = {}) => {
  let env = process.env.NODE_ENV === 'development' ? 'dev' : process.env.NODE_ENV;
  return buildURL(`https://itrvl-${env}-media.imgix.net/${s3Key}`, imgixParams);
};

const getPdfUrl = (mediaContent = {}) => {
  const { s3Bucket, s3Key } = mediaContent;
  return `https://${s3Bucket}.imgix.net/${s3Key}`;
};

const getVideoSources = videos => {
  let sources = [];
  if (videos) {
    const { hls, mp4 } = videos;
    if (hls) {
      sources.push({ src: `${VideoCDN}/${hls}`, type: 'application/x-mpegURL' });
    }
    if (mp4) {
      sources.push({ src: `${VideoCDN}/${mp4}`, type: 'video/mp4' });
    }
  }
  return sources;
};

export {
  heroForResolution,
  heroForResolutionFromLodgeList,
  heroForItineraryFromLodgeList,
  singleMediaContentToUrl,
  safeSingleMediaContentToUrl,
  getIconForFeature,
  singleMediaContentToPreview,
  manyMediaContentToUrls,
  ImageCDN,
  VideoCDN,
  fetchMediaContentImage,
  manyMediaContentToTypes,
  itineraryToPosterUrl,
  mediaContentToPosterUrl,
  mediaContentToVideoUrl,
  cdnUri,
  cdnUrl,
  getStaticMediaURL,
  getVideoSources,
  getPdfUrl,
  resizeImgixUrl,
  placeholderUrl,
  getPosterMedia,
};
