import { useCallback, useMemo } from 'react';
import { forEach, reduce, size } from 'lodash';
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from 'components/ItineraryBuilder/ItineraryBuilderEntry';
// @todo: deprecate
import { QUOTE_ONLY_MODE } from './constants';
import { formatDate, generateDatesByWeek, getCalendarBounds } from './utils';
import { useBuilderStore } from './store';
import { useApi } from 'common/hooks/api';
import { weekByWeekDefaultQueryArgs, useCampFeaturesMapQuery } from './queries';
import { useMountedState, useRafLoop, useRafState } from 'react-use';
import logger from 'itrvl-logger';
const log = logger(__filename);
log.trace(__filename);

export const useSegmentsOriginal = () => {
  const segmentsOriginal = useBuilderStore(state => state.data.segmentsOriginal);
  return segmentsOriginal;
};

export const useSegments = () => {
  const segmentsById = useBuilderStore(state => state.data.segmentsById);
  const segmentIds = useBuilderStore(state => state.data.segmentIds);
  return useMemo(() => segmentIds.map(id => segmentsById[id]).filter(Boolean), [segmentsById, segmentIds]);
};

export const useSupplierSegments = supplierCode => {
  const segments = useSegments();
  return useMemo(() => segments.filter(segment => segment.supplierCode === supplierCode), [segments, supplierCode]);
};

export const useSegmentAssignedTravelers = segmentId => {
  const segment = useBuilderStore(state => state.data.segmentsById[segmentId]);
  return useMemo(() => {
    return reduce(
      segment?.rooms,
      (totals, room) => {
        totals.adults += room.adults || 0;
        totals.children += room.children || 0;
        return totals;
      },
      {
        adults: 0,
        children: 0,
      },
    );
  }, [segment.rooms]);
};

export const useSegmentRemainingTravelers = segmentId => {
  const adults = useBuilderStore(state => state.data.adults);
  const children = useBuilderStore(state => state.data.children);
  const segment = useBuilderStore(state => state.data.segmentsById[segmentId]);
  return useMemo(() => {
    return reduce(
      segment?.rooms,
      (totals, room) => {
        totals.adults -= room.adults || 0;
        totals.children -= room.children || 0;
        return totals;
      },
      {
        adults: adults || 0,
        children: children || 0,
      },
    );
  }, [segment?.rooms, adults, children]);
};

export const useSupplierBounds = supplierCode => {
  const globalDate = useBuilderStore(state => state.data.date);
  const supplierMonth = useBuilderStore(state => state.ui.supplierMonth);
  const date = supplierMonth?.[supplierCode] || globalDate || new Date();
  const [startBound, endBound] = getCalendarBounds(date);
  return { date, startBound, endBound };
};

export const useCustomMutation = (mutationKey, mutationFn, options) => {
  const query = useQuery(['CustomMutation', mutationKey], async () => await Promise.resolve(false), {
    retry: false,
    cacheTime: Number.Infinity,
    staleTime: Number.Infinity,
  });
  const queryError = useQuery(['CustomMutationError', mutationKey], async () => await Promise.resolve(false), {
    retry: false,
    cacheTime: Number.Infinity,
    staleTime: Number.Infinity,
  });
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (...params) => {
      queryClient.setQueryData(['CustomMutationError', mutationKey], false);
      return await mutationFn(...params);
    },
    ...Object.assign(Object.assign({}, options), {
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData(['CustomMutation', mutationKey], data);
        if (options === null || options === void 0 ? void 0 : options.onSuccess) options.onSuccess(data, variables, context);
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['CustomMutationError', mutationKey], err);
        if (options === null || options === void 0 ? void 0 : options.onError) options.onError(err, variables, context);
      },
    }),
  });
  const isLoading = useIsMutating(mutationKey);
  // We need typecasting here due the ADT about the mutation result, and as we're using a data not related to the mutation result
  // The typescript can't infer the type correctly.
  return Object.assign(Object.assign({}, mutation), {
    data: query.data,
    isLoading: !!isLoading,
    error: queryError.data,
    isError: !!queryError.data,
  });
};

export const useQuoteOnly = () => {
  const quoteMode = useBuilderStore(state => state.data.quoteMode);
  return quoteMode === QUOTE_ONLY_MODE;
};

export const usePrefetchMonth = supplierCode => {
  const Api = useApi();
  const isQuoteOnly = useQuoteOnly();
  const adults = useBuilderStore(state => state.data.adults);
  const childrenAges = useBuilderStore(state => state.data.childrenAges);

  return useCallback(
    (dates, fromStart) => {
      forEach(generateDatesByWeek(dates, fromStart), date => {
        const dateString = formatDate(date);
        queryClient.prefetchQuery(weekByWeekDefaultQueryArgs(supplierCode, dateString, adults, childrenAges, [], Api, !isQuoteOnly));
      });
    },
    [Api, supplierCode, isQuoteOnly, adults, childrenAges],
  );
};

export const useBackgroundAvailabilityPoll = supplierCode => {
  const isMounted = useMountedState();
  const [isLoading, setIsLoading] = useRafState(false);
  useRafLoop(() => {
    const isFetching = queryClient.isFetching({ queryKey: ['availability', supplierCode] }) > 0;
    if (isFetching !== isLoading) {
      isMounted() && setIsLoading(isFetching);
    }
  });
};

export const useCampFeatures = (featureIds = []) => {
  const query = useCampFeaturesMapQuery();
  let features = [];
  if (size(featureIds) > 0 && query.isFetched && size(query.data) > 0) {
    features = featureIds.map(featureId => query.data[featureId]).filter(Boolean);
  }
  return {
    ...query,
    features,
  };
};
