const Child = () => (
  <svg viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 2.5C6.25 3.48438 5.45703 4.25 4.47266 4.25C3.51562 4.25 2.72266 3.48438 2.72266 2.5C2.72266 1.54297 3.51562 0.75 4.47266 0.75C5.45703 0.75 6.25 1.54297 6.25 2.5ZM4.47266 1.625C4.00781 1.625 3.59766 2.03516 3.59766 2.5C3.59766 2.99219 4.00781 3.375 4.47266 3.375C4.96484 3.375 5.375 2.99219 5.375 2.5C5.375 2.03516 4.96484 1.625 4.47266 1.625ZM0.917969 9.30859C0.808594 9.5 0.535156 9.58203 0.316406 9.44531C0.125 9.30859 0.0429688 9.0625 0.179688 8.84375L1.38281 6.875C2.03906 5.78125 3.21484 5.125 4.5 5.125C5.75781 5.125 6.93359 5.78125 7.58984 6.875L8.79297 8.84375C8.92969 9.0625 8.84766 9.30859 8.65625 9.44531C8.4375 9.58203 8.16406 9.5 8.05469 9.30859L6.85156 7.33984C6.79688 7.25781 6.74219 7.17578 6.66016 7.09375V14.3125C6.66016 14.5586 6.46875 14.75 6.22266 14.75C6.00391 14.75 5.78516 14.5586 5.78516 14.3125V11.25H3.1875V14.3125C3.1875 14.5586 2.96875 14.75 2.75 14.75C2.50391 14.75 2.3125 14.5586 2.3125 14.3125V7.09375C2.23047 7.17578 2.17578 7.25781 2.12109 7.33984L0.917969 9.30859ZM5.78516 10.375V6.35547C5.40234 6.13672 4.96484 6 4.5 6C4.00781 6 3.57031 6.13672 3.1875 6.35547V10.375H5.78516Z"
      fill="currentColor"
    />
  </svg>
);

export default Child;
