import { useState } from 'react';
import { merge } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Collapse, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(theme => ({
  formControlRoot: {
    width: '100%',
  },
  inputRoot: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
}));

export default function RegionSelector({ options = [], value, error, onChange, regionInput, setRegionInput }) {
  const classes = useStyles();
  const inputClasses = { classes: { underline: classes.inputRoot } };

  return (
    <FormControl className={classes.formControlRoot}>
      <Autocomplete
        options={options}
        groupBy={option => option.country}
        renderGroup={paramsGroup => {
          return <CollapseGroup key={paramsGroup.key} params={paramsGroup} />;
        }}
        getOptionLabel={option => option.name}
        style={{ width: '100%' }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            className={classes.inputRoot}
            placeholder="Select Region"
            InputProps={merge(params.InputProps || {}, inputClasses)}
            value={regionInput}
          />
        )}
        //renderOption={option => option.name} // Not for mui5.
        limitTags={2}
        // multiple={true} TODO: conditionally enable this and the next line when we want to allow multiple selections
        disableCloseOnSelect={false}
        clearText="Clear all"
        onChange={onChange}
        value={value}
        inputValue={regionInput}
        onInputChange={(_, val) => setRegionInput(val)}
      />
      <FormHelperText error>{error || <>&nbsp;</>}</FormHelperText>
    </FormControl>
  );
}

const CollapseGroup = ({ params }) => {
  const [open, setOpen] = useState(true);

  return (
    <Box>
      <IconButton aria-label="expand row" onClick={() => setOpen(!open)} size="large">
        {open ? <RemoveIcon /> : <AddIcon />}
      </IconButton>{' '}
      <b style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        {params.group}
      </b>
      <Collapse in={open} timeout="auto">
        {params.children}
      </Collapse>
    </Box>
  );
};
