import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { values, size } from 'lodash';
import shallow from 'zustand/shallow';

import PinsList from './PinsList';
import AccommodationsList from './AccommodationsList';
import Chip from '@mui/material/Chip';
import AccommodationCount from './AccommodationCount';
import PinCount from './PinCount';

import { useBuilderStore } from './store';

const useStyles = makeStyles(
  {
    root: {
      padding: '0 32px 100px 32px',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1440,
      margin: '0 auto',
    },
    cardWrap: {
      paddingBottom: 23,
      display: 'flex',
      height: 335,
    },
    pins: {
      paddingBottom: 23,
      marginBottom: 46,
      borderBottom: '1px solid #E9E1D7',
    },
    all: {
      marginBottom: 23,
    },
    regions: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: 10,
      marginBottom: 23,
    },
    infinite: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      paddingTop: 40,
      paddingBottom: 80,
    },
    allChip: {
      backgroundColor: '#ccc',
    },
  },
  {
    name: 'Content',
  },
);

const Content = () => {
  const classes = useStyles();
  const pinnedAccommodations = useBuilderStore(state => values(state.data.pinnedAccommodationMap), shallow);

  // @todo: deprecate
  // const items = useBuilderStore(state => state.ui.accommodations.items);

  const selectedRegions = useBuilderStore(state => values(state.ui.countries.selectedMap), shallow);
  const removeRegion = useBuilderStore(state => state.actions.regions.removeRegion);
  const removeAllRegions = useBuilderStore(state => state.actions.regions.removeAllRegions);
  const term = useBuilderStore(state => state.ui.accommodations.term);
  const hasPins = size(pinnedAccommodations) > 0;
  const hasRegions = size(selectedRegions) > 0;
  return (
    <div data-test-id="Content" className={classes.root} /*ref={animationParent}*/>
      <div className={classes.regions}>
        <span>Regions:</span>
        {!hasRegions && <Chip color="primary" key="all" label="All Regions" />}
        {selectedRegions.map(region => {
          return <Chip color="primary" key={region.value} label={region.label} onDelete={() => removeRegion(region.value)} />;
        })}
        {hasRegions && <Chip className={classes.allChip} key="all" label="Clear Regions" onDelete={removeAllRegions} />}
      </div>

      {term && (
        <>
          <AccommodationsList />
        </>
      )}

      {term && hasPins && (
        <div style={{ marginBottom: 20 }}>
          <PinCount />
        </div>
      )}

      <PinsList />

      {hasPins && !term && (
        <div style={{ marginBottom: 30 }}>
          <AccommodationCount />
        </div>
      )}

      {!term && (
        <>
          <AccommodationsList />
        </>
      )}

      {/* @todo: this is if we want virtualization, but will break default browser search */}
      {/* <Virtuoso
        useWindowScroll
        data={cards}
        itemContent={(index, item) => {
          return (
            <div className={classes.cardWrap}>
              <Card key={index} {...item} />
            </div>
          );
        }}
        components={{
          Footer: () => (
            <div>
              <CircularProgress color="primary" />
            </div>
          ),
        }}
      /> */}
    </div>
  );
};

export default React.memo(Content);
