const EditPen = ({ color }) => (
  <svg viewBox="0 0 222.9 323.6">
    <path
      d="M0,314.7c-0.2,3.1,1.4,6,4,7.7c1.3,0.8,2.9,1.2,4.4,1.2s3-0.4,4.3-1.2l23.6-14l1-0.6L79,283.2c1.2-0.7,2.2-1.7,2.9-2.8
		l139-227.2c1.9-3.2,2.5-6.9,1.6-10.4c-0.8-3.4-3-6.4-5.9-8.3c-0.1,0-0.2-0.1-0.2-0.2L163.5,2c-3.1-1.9-6.8-2.5-10.3-1.6
		c-3.4,0.8-6.4,2.9-8.2,5.8c-0.1,0.1-0.1,0.2-0.2,0.3l-21.3,34.8l-1.6,2.6L5.7,233.9c-0.7,1.2-1.1,2.5-1.2,3.9 M68.6,269.8
		l-36.1,21.3l-13.8-8.5l2.5-41.8L132.6,58.6l47.5,29L68.6,269.8z M188.9,73.3l-47.5-29l16-26.3l47.5,29L188.9,73.3z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default EditPen;
