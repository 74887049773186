import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { size } from 'lodash';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import Image from './Image';
import Tooltip from 'components/v2/Tooltip';

import TimesCircleIcon from './icons/TimesCircle';
import PlusIcon from './icons/Plus';

import { getDateString } from './utils';
import { useBuilderStore } from './store';

const useStyles = makeStyles(theme => ({
  root: {},
  segments: {
    backgroundColor: '#FCFBFA',
    padding: '16px 0',
    '& section': {
      color: theme.palette.primary.main,
    },
  },

  segmentCard: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    padding: '0 10px',
    height: 72,
    '& section > *': {
      whiteSpace: 'nowrap',
    },
  },

  segmentAdd: {
    textTransform: 'uppercase',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    cursor: 'pointer',
    transition: theme.transitions.create(['color']),
    '& span': {
      lineHeight: 1,
      // there's whitespace under the letters so its impossible to alignItems: center
      marginTop: 2,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      width: 14,
      height: 14,
    },
  },

  label: {
    whiteSpace: 'nowrap',
  },
  delete: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    flexShrink: 0,
    '& path': {
      transition: theme.transitions.create(['fill']),
      fill: theme.palette.primary.main,
      '&:hover': {
        fill: theme.palette.secondary.main,
      },
    },
  },

  removeBtn: {
    color: '#fff',
  },

  media: {
    width: 80,
    height: 64,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  tooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

// @todo: deprecate these!
// editSegmentAtIndex(index)
// removeSegmentAtIndex(index)

const Segment = props => {
  const { id } = props;
  const classes = useStyles();
  const history = useHistory();
  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const deleteSegmentById = useBuilderStore(state => state.actions.accommodations.deleteSegmentById);
  const editSegmentById = useBuilderStore(state => state.actions.accommodations.editSegmentById);
  const dateString = getDateString(segment);

  const handleEdit = () => {
    editSegmentById(id);
    history.push('/itinerary-builder/details');
  };

  return (
    <div data-test-id={`segment-${segment.campName}`}>
      <div className={classes.segmentCard}>
        <div className={classes.media}>
          <Image s3Key={segment?.featuredImage?.s3Key} />
        </div>
        <section>
          <label className={classes.label}>{segment.campName}</label>
          {dateString && <div>{dateString}</div>}
          {size(segment.rooms) === 1 && (
            <div>
              {segment.rooms[0]?.room?.name} ({segment.rooms[0].adults} Adult{segment.rooms[0].adults > 1 ? 's' : ''}
              {segment.rooms[0].children > 0 ? `, ${segment.rooms[0].children} Child${segment.rooms[0].children > 1 ? 'ren' : ''}` : ''})
            </div>
          )}
          {size(segment.rooms) > 1 && <div>{size(segment.rooms)} Rooms</div>}
          <div className={classes.segmentAdd} onClick={handleEdit} role="button" aria-label="edit rooms">
            <PlusIcon /> <span>Edit Rooms</span>
          </div>
        </section>
        <Tooltip
          interactive
          title={
            <div className={classes.tooltip}>
              <div>Remove Accommodation?</div>
              <Button className={classes.removeBtn} onClick={() => deleteSegmentById(id)}>
                Yes
              </Button>
            </div>
          }
        >
          <div className={classes.delete} data-test-id="remove-accommodation-icon" onClick={() => deleteSegmentById(id)}>
            <TimesCircleIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default Segment;
