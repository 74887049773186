import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import NiceModal from '@ebay/nice-modal-react';
import CreateAddClientModal from 'components/v2/Modals/CreateAddClientModal';

import PlusIcon from './icons/Plus';
import ChangeCircleIcon from './icons/ChangeCircle';

import { useBuilderStore } from './store';
import { useUser } from 'common/hooks/user';
import { useCreateClient } from 'mutations/client';

const useStyles = makeStyles(theme => ({
  btn: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    textTransform: 'uppercase',
    height: '100%',
    padding: '0 17px',
    fontWeight: 400,
    lineHeight: 1,
    fontSize: 12,
    cursor: 'pointer',
    transition: theme.transitions.create(['color']),
    '& svg': {
      width: 13,
      height: 13,
    },
    '& path': {
      transition: theme.transitions.create(['fill']),
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  client: {
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  icons: {
    '& svg': {
      width: 20,
      height: 20,
    },
  },
}));

const AddClientButton = () => {
  const classes = useStyles();
  const client = useBuilderStore(state => state.data.client);
  const setClient = useBuilderStore(state => state.actions.setClient);
  const clearClient = useBuilderStore(state => state.actions.clearClient);
  const user = useUser();
  const createClient = useCreateClient();
  const updateClient = data => {
    setClient(data);
    user.updateClient(data);
  };

  const agencyId = user?.agency?.id;

  return (
    <div
      className={clsx(classes.btn, client && classes.client)}
      onClick={() => {
        NiceModal.show(CreateAddClientModal, {
          canDelete: true,
          agencyId,
          onNewClient: (userData, modal) => {
            modal.show({ ...modal.args, loading: true });
            createClient.mutate(userData, {
              onSuccess: client => {
                updateClient(client);
                modal.hide();
              },
              onError: error => modal.show({ ...modal.args, loading: false, error }),
            });
          },
          onSelectExistingClient: (client, modal) => {
            updateClient(client);
            modal.hide();
          },
          onDelete: (_, modal) => {
            user.clearClient();
            clearClient();
            modal.hide();
          },
          client,
        });
      }}
      role="button"
      aria-label={client ? 'change client' : 'add client'}
    >
      {client ? (
        <>
          <div className={classes.icons}>
            <ChangeCircleIcon />
          </div>
          {client.name}
        </>
      ) : (
        <>
          <PlusIcon /> <span>Add Client</span>
        </>
      )}
    </div>
  );
};

export default AddClientButton;
