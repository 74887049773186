import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useModal } from '@ebay/nice-modal-react';
import clsx from 'clsx';
import { isArray, map } from 'lodash';

import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import Button from 'components/v2/Button';
import CloseIcon from '@mui/icons-material/Close';
import AsyncButton from 'components/v2/AsyncButton';
import { noop } from 'common';

const GUTTER = 50;
const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.4285714286,
    color: '#000000',
    '& strong': {
      fontWeight: 500,
    },
    '& > header': {
      position: 'relative',
      padding: GUTTER,
      '& h1': {
        margin: 0,
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: 1.333333333333333,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
        // @todo: var me?
        color: '#221F20',
      },
    },
    '& > section': {
      /* ITRVL Palette / Stone Grey / 60% White */
      background: '#F8F7F5',
      padding: GUTTER,
      display: 'flex',
      flexDirection: 'column',
      gap: 23,
    },
  },
  controls: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    lineHeight: 1,
    cursor: 'pointer',
    '& svg': {
      fontSize: 18,
    },
  },
}));

export default function BaseModal(props) {
  const {
    title,
    subtext,
    showHide = true,
    children,
    controls,
    fullWidth,
    maxWidth = false,
    disableHide = false,
    paperProps = {},
    // control props
    showControls = true,
    loading,
    // deny
    denyText,
    onDeny,
    deniedDisabled,
    deniedProps = {},
    // confirm
    confirmText,
    onConfirm,
    confirmDisabled,
    confirmedProps = {},
    labelledById = 'dialog-title', // this is for the initial modal, overwrite for nested or stacked modals
    ...rest
  } = props;
  const buttonConfirmText = confirmText || confirmedProps.text || 'Ok';
  const modal = useModal();
  const classes = useStyles(props);
  return (
    <Dialog
      open={modal.visible}
      onClose={() => (disableHide ? noop() : modal.hide())}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionProps={{
        // tear down modal so state is fresh on remount
        onExited: modal.remove,
      }}
      PaperProps={paperProps}
      aria-labelledby={labelledById}
      {...rest}
    >
      <Typography component="div" className={classes.root} data-test-id={title}>
        {/* @todo: lets use these */}
        {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}> */}
        <header>
          {title && isArray(title) ? (
            map(title, t => (
              <h1 id={labelledById} key={t}>
                {t}
              </h1>
            ))
          ) : (
            <h1 id={labelledById}>{title}</h1>
          )}
          {subtext && isArray(subtext) ? (
            map(subtext, s => (
              <span className="baseModalSubtext" data-test-id="modal-description" key={s}>
                {s}
              </span>
            ))
          ) : (
            <span className="baseModalSubtext">{subtext}</span>
          )}
          {showHide && (
            <div className={clsx('hoverable', classes.close)} onClick={modal.hide} role="button" aria-label="close modal">
              <CloseIcon />
            </div>
          )}
        </header>
        <section aria-label="content">
          {children && (
            <div className="baseModalChildren" data-test-id="content">
              {children}
            </div>
          )}
          {/* @todo: lets use these */}
          {/* <DialogActions> */}
          {/* @todo: probably provide default controls? but allow overrides */}
          {showControls ? (
            controls ? (
              <footer>{controls}</footer>
            ) : (
              <footer className={clsx(classes.controls, 'footer-controls')}>
                <Button onClick={onDeny ? () => onDeny(modal) : modal.hide} denied={deniedDisabled} {...deniedProps}>
                  {denyText || deniedProps.text || 'Cancel'}
                </Button>
                <AsyncButton
                  onClick={loading ? noop : onConfirm ? () => onConfirm(modal) : modal.hide}
                  loading={loading}
                  color="primary"
                  variant="contained"
                  disabled={confirmDisabled || confirmedProps.disabled}
                  className={classes.confirmButton}
                  overlay
                  {...confirmedProps}
                  aria-label={buttonConfirmText}
                >
                  {buttonConfirmText}
                </AsyncButton>
              </footer>
            )
          ) : (
            <></>
          )}
        </section>
      </Typography>
    </Dialog>
  );
}
