import React from 'react';

import Button from './Button';

import { useHistory } from 'react-router-dom';
import { useBuilderStore } from './store';

const ViewDetailButton = props => {
  const history = useHistory();
  const viewDetails = useBuilderStore(state => state.actions.accommodations.viewDetails);
  const viewMode = useBuilderStore(state => state.ui.viewMode);

  const handleClick = () => {
    viewDetails(props);
    history.push('/itinerary-builder/details');
  };

  return (
    <Button variant="contained" color="secondary" disableElevation onClick={handleClick}>
      {viewMode === 'card' ? 'View' : 'View Detail'}
    </Button>
  );
};

export default ViewDetailButton;
