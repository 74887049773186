import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, adaptV4Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const fontFamily = 'Atyp Display';
const fontFamilySecondary = 'Athelas';

export const v2Theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily,
      fontFamilySecondary,
      h1: {
        fontFamily: fontFamilySecondary,
        fontSize: '56px',
        fontWeight: 700,
      },
      h2: {
        fontFamily: fontFamilySecondary,
        fontSize: '48px',
        fontWeight: 700,
      },
      h3: {
        fontFamily: fontFamilySecondary,
        fontSize: '42px',
        fontWeight: 700,
      },
      h4: {
        fontFamily: fontFamilySecondary,
        fontSize: '36px',
        fontWeight: 700,
      },
      h5: {
        fontFamily: fontFamilySecondary,
        fontSize: '32px',
        fontWeight: 700,
      },
      h6: {
        fontFamily: fontFamilySecondary,
        fontSize: '24px',
        fontWeight: 700,
      },
      body1: {
        fontFamily: fontFamily,
        fontSize: '16px',
        fontWeight: 400,
      },
      body2: {
        fontFamily: fontFamily,
        fontSize: '12px',
        fontWeight: 400,
      },
      subtitle1: {
        fontFamily: fontFamily,
        fontSize: '14px',
        fontWeight: 400,
      },
    },
    palette: {
      primary: {
        main: '#123438',
      },
      secondary: {
        main: '#FF6E00',
      },
      success: {
        main: '#4DAA62',
        contrastText: '#fff',
      },
      error: {
        main: '#C34747',
      },
      darkGreen: {
        black80: '#071516',
        black60: '#071516',
        black40: '#0B1F22',
        black20: '#0E2A2D',
        base: '#123438',
        white20: '#415D60',
        white40: '#718588',
        white60: '#A0AEAF',
        white80: '#D0D6D7',
      },
      greyBlue: {
        black80: '#101315',
        black60: '#21262A',
        black40: '#313840',
        black20: '#424B55',
        base: '#525E6A',
        white20: '#757E88',
        white40: '#979EA6',
        white60: '#BABFC3',
        white80: '#DCDFE1',
      },
      errorRed: {
        black80: '#270E0E',
        black60: '#4E1C1C',
        black40: '#752B2B',
        black20: '#9C3939',
        base: '#C34747',
        white20: '#CF6C6C',
        white40: '#DB9191',
        white60: '#E7B5B5',
        white80: '#FCDADA',
      },
      successGreen: {
        black80: '#0F2214',
        black60: '#1F4427',
        black40: '#2E663B',
        black20: '#3E884E',
        base: '#4DAA62',
        white20: '#71BB81',
        white40: '#94CCA1',
        white60: '#B8DDC0',
        white80: '#DBEEE0',
      },
      darkSand: {
        black80: '#24201D',
        black60: '#494139',
        black40: '#6D6156',
        black20: '#928272',
        base: '#B6A28F',
        white20: '#C5B5A5',
        white40: '#D3C7BC',
        white60: '#E2DAD2',
        white80: '#F0ECE9',
      },
      stoneGrey: {
        black80: '#302F2E',
        black60: '#5F5E5C',
        black40: '#8F8D89',
        black20: '#BEBCB7',
        base: '#EEEBE5',
        white20: '#F1EFEA',
        white40: '#F5F3EF',
        white60: '#F8F7F5',
        white80: '#FCFBFA',
      },
      vividOrange: {
        black80: '#331600',
        black60: '#662C00',
        black40: '#994200',
        black20: '#CC4700',
        base: '#FF6E00',
        white20: '#FF8B33',
        white40: '#FFA866',
        white60: '#FFC599',
        white80: '#FFE2CC',
      },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 0,
        },
        containedPrimary: {
          '&:hover': {
            color: '#fff',
          },
          '& svg': {
            color: '#fff',
          },
        },
      },
      MuiFab: {
        root: {
          boxShadow: 'none',
        },
        label: {
          display: 'flex',
        },
      },
      MuiInputBase: {
        input: {
          padding: '13px 15px',
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          marginRight: 15,
        },
      },
      MuiSelect: {
        select: {
          '&$disabled': {
            cursor: 'not-allowed',
          },
        },
      },
      MuiList: {
        root: {
          '& > a': {
            color: 'rgba(0, 0, 0, 0.8)',
            '&:hover': {
              color: 'rgba(0, 0, 0, 0.8)',
            },
          },
        },
        padding: {
          padding: 20,
          paddingTop: 20,
          paddingBottom: 20,
        },
      },

      MuiListItem: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#525E6A',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#6C7884',
              color: '#fff',
            },
          },
        },
        button: {
          '&:hover': {
            backgroundColor: '#EBF7FF',
          },
        },
      },

      MuiPaper: {
        root: {
          borderRadius: 0,
        },
        rounded: {
          borderRadius: 0,
        },
      },
    },
  }),
);

const useGlobalStyles = makeStyles(
  theme => ({
    '@global': {
      ':root': {
        '--primary-color': theme.palette.primary.main,
        '--secondary-color': theme.palette.secondary.main,
        '--error-color': theme.palette.error.main,
      },
      '.header': {
        fontSize: 16,
        fontFamily: 'Atyp Display',
        fontWeight: 500,
      },
      '.title-text': {
        textTransform: 'uppercase',
      },
      '.hoverable': {
        transition: theme.transitions.create(['color']),
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      },
      '& .link': {
        fontSize: 14,
        lineHeight: 1.3,
        textDecorationLine: 'underline',
        textTransform: 'uppercase',
        color: '#000000',
      },
      '& .linkHidden': {
        fontSize: 14,
        lineHeight: 1,
        textDecorationLine: 'none',
        color: '#000000',
      },
      '& .linkOff': {
        pointerEvents: 'none',
      },
      '.line-clamp-1': {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      '.line-clamp-2': {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      '.line-clamp-3': {
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      '.error': {
        color: theme.palette.error.main,
      },
      // @todo: probably move this into a better abstract css place but global for now is fine
      '.react-datepicker.itinerary-builder-calendar': {
        // datepicker lib overrides
        borderRadius: 0,
        border: 0,
        padding: 20,
        '& .react-datepicker__month-container': {
          float: 'unset',
        },
        // @todo: maybe use paper for all these?
        // boxShadow: theme.shadows[1],
        '& .react-datepicker__header': {
          padding: 0,
          backgroundColor: '#fff',
          borderBottom: '1px solid #C6C6C5',
        },
        '& .react-datepicker__week': {
          marginBottom: 5,
        },
        '& .react-datepicker__day-names, & .react-datepicker__week': {
          display: 'flex',
          gap: 5,
        },
        '& .react-datepicker__day-name': {
          color: '#C6C6C5',
        },
        '& .react-datepicker__day': {
          borderRadius: '50%',
          position: 'relative',
        },
        '& .react-datepicker__day--outside-month:not(.is-own-date)': {
          opacity: 0.3,
        },
        '& .react-datepicker__day-name, & .react-datepicker__day': {
          width: 'calc(100% / 7)',
          aspectRatio: 1,
          display: 'flex',
          margin: 0,
          justifyContent: 'center',
          alignItems: 'center',
        },
        '& .btn': {
          color: '#221F20',
          // override default button styles
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          '& svg': {
            width: 9,
            height: 'auto',
          },
        },
        // mui
        '& .MuiInputBase-root': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        '& .MuiSelect-root': {
          minHeight: 'auto',
          padding: 10,
          fontSize: 12,
          lineHeight: 1,
        },
        '& .MuiSelect-icon': {
          position: 'relative',
          top: 'auto',
          right: 'auto',
          marginRight: 7,
        },
      },
    },
  }),
  {
    name: 'V2GlobalStyles',
  },
);

const GlobalStylesWrapper = ({ children }) => {
  useGlobalStyles();
  return children;
};

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={v2Theme}>
    <GlobalStylesWrapper>{children}</GlobalStylesWrapper>
  </MuiThemeProvider>
);

export default ThemeProvider;
