import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MuiButton from '@mui/material/Button';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px 24px',
    gap: 10,

    width: 214,
    height: 40,

    // background: "#FFC599",
    // color: "#FF8B33",

    flex: 'none',
    lineHeight: 1,
    flexGrow: 0,

    borderRadius: 0,

    '&.MuiButton-textSecondary': {
      backgroundColor: '#E2DAD2',
      color: '#000',
      width: 'auto',
      alignSelf: 'flex-start',
    },
  },
});

const Button = props => {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <MuiButton classes={{ root: classes.root }} {...rest}>
      {children}
    </MuiButton>
  );
};

export default Button;
