import {
  format,
  parse,
  isSameDay,
  isSameMonth,
  eachDayOfInterval,
  eachWeekOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInWeeks,
  addWeeks,
  addDays,
  subDays,
  isBefore,
  isAfter,
} from 'date-fns';
import { reduce, isEqual } from 'lodash';

import { DIGEST_DATE_FORMAT, DATE_FORMAT_INTERNAL, WEEK_OFFSET } from './constants';
import logger from 'itrvl-logger';

const log = logger(__filename);
log.trace(__filename);

export const formatDate = date => format(date, DATE_FORMAT_INTERNAL);
export const parseDate = dateString => parse(dateString, 'yyyy-M-d', new Date());
export const formatMonthDay = date => format(date, 'MMM d');

export const getDateString = (segment = {}) => {
  const { startDate, endDate } = segment;
  if (!startDate || !endDate) {
    return '';
  }
  if (isSameDay(startDate, endDate)) {
    return format(startDate, 'MMM d');
  }
  if (isSameMonth(startDate, endDate)) {
    return `${format(startDate, 'MMM d')} - ${format(endDate, 'd')}`;
  }
  return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
};

// @todo: maybe DRY these out? they are very similar
export const formatDigestDates = (startDate, endDate) => {
  if (!startDate && !endDate) {
    return 'No Dates Selected';
  }
  if (startDate && !endDate) {
    return format(startDate, DIGEST_DATE_FORMAT);
  }
  if (isSameDay(startDate, endDate)) {
    return format(startDate, DIGEST_DATE_FORMAT);
  }
  if (isSameMonth(startDate, endDate)) {
    return `${format(startDate, 'MMMM d')} - ${format(endDate, 'd yyyy')}`;
  }
  return `${format(startDate, 'MMMM d')} - ${format(endDate, 'MMMM d yyyy')}`;
};

export const calculateDateMap = segments =>
  reduce(
    segments,
    (acc, segment = {}) => {
      const { startDate, endDate } = segment;
      let days = [];
      if (!startDate && !endDate) {
        return acc;
      }
      if (startDate && !endDate) {
        days.push(startDate);
      }
      if (startDate && endDate) {
        if (isEqual(segment.startDate, segment.endDate)) {
          days.push(segment.startDate);
        } else {
          days = eachDayOfInterval({
            start: segment.startDate,
            end: segment.endDate,
          });
        }
      }
      days.forEach((date, index) => {
        acc[date] = {
          ...segment,
          ...(index === 0 && { first: true }),
          ...(index === days.length - 1 && { last: true }),
        };
      });
      return acc;
    },
    {},
  );

/**
 * Generates the bounds for the calendar fetch. As the calendar is set to be fixed height, each month
 * includes ${WEEK_OFFSET} worth of weeks (padding from previous to next month).
 * We fill this padding in to the bounds
 * @todo: We can trim this at some point and potentially use an infinite/paged query instead
 * @param {*} date
 * @returns
 */
export const getCalendarBounds = date => {
  const start = startOfWeek(startOfMonth(date));
  let end = endOfWeek(endOfMonth(date));
  let diff = differenceInWeeks(end, start);
  while (diff < WEEK_OFFSET) {
    end = addWeeks(end, 1);
    diff = differenceInWeeks(end, start);
  }
  return [start, end];
};

export const isAvailable = n => n > 0 || n === -2 || n === -1;
export const isOptionOpen = n => n !== undefined && n !== 'C' && n !== 'I';
export const isUnavailable = n => !isAvailable(n);

export const travelersToFillRoom = (room, maxAdults, maxChildren) => {
  const { minPax, guestNormal, normalAdults, normalChildren } = room;
  const totals = {
    adults: 0,
    children: 0,
    adultsErr: undefined,
    minErr: undefined,
  };

  if (maxAdults === 0 && maxChildren > 0) {
    totals.adultErr = true;
    return totals;
  }

  // assign 1 adult, as its required
  totals.adults = 1;

  if (maxChildren > normalChildren) {
    totals.children = normalChildren;
  }

  if (maxChildren <= normalChildren) {
    totals.children = maxChildren;
  }

  if (totals.children > guestNormal) {
    totals.children = guestNormal;
  }

  // now we need to fill in remainder adults
  if (totals.children + totals.adults < guestNormal) {
    let remainder = guestNormal - (totals.children + totals.adults);
    if (remainder > 0 && normalAdults > totals.adults) {
      // normalAdults -
      totals.adults = Math.min(remainder + 1, normalAdults, maxAdults);
    }
  }

  if (totals.adults + totals.children < minPax) {
    return {
      adults: 0,
      children: 0,
      adultErr: undefined,
      minErr: true,
    };
  }

  return totals;
};

const INITIAL_OFFSET_BOUND = 3;

export const generateDatesFromStart = date =>
  eachDayOfInterval({
    start: startOfWeek(startOfMonth(date)),
    end: endOfWeek(endOfMonth(date)),
  });

export const generateDatesByWeek = date => {
  let datesByWeek = eachWeekOfInterval({
    start: startOfWeek(startOfMonth(date)),
    end: endOfWeek(endOfMonth(date)),
  });

  return datesByWeek;
};

export const generateDates = (date, fromStart) => {
  if (fromStart) {
    let datesFromStart = generateDatesFromStart(date);
    return datesFromStart;
  }

  const now = new Date();
  const min = startOfWeek(startOfMonth(date));
  const max = endOfWeek(endOfMonth(date));
  const dates = [];
  // lets be naive and just fetch the initial bound of:
  // current date + 3 days, current date - 3 days, then fill in
  // we should probably add some logic here to determine if were near the end
  // bounds of the calendar and not optimistically fetch too far across the fold?
  dates.push(date);
  // lazy creates memory usage but whatever
  Array.from({ length: INITIAL_OFFSET_BOUND }, (_o, i) => dates.push(addDays(date, i + 1)));
  Array.from({ length: INITIAL_OFFSET_BOUND }, (_o, i) => dates.push(subDays(date, i + 1)));

  const start = addDays(date, INITIAL_OFFSET_BOUND + 1);
  eachDayOfInterval({
    // detect if this is the same as max
    start: isAfter(start, max) ? max : start,
    end: max,
  }).forEach(date => {
    dates.push(date);
  });

  const end = subDays(date, INITIAL_OFFSET_BOUND - 1);
  eachDayOfInterval({
    start: min,
    end: isBefore(end, min) ? min : end,
  })
    .reverse()
    .forEach(date => dates.push(date));

  return dates.filter(date => !isBefore(date, now));
};
