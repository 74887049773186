import tinycolor from 'tinycolor2';
const { defaultTheme } = require('itrvl-types');

const darkText = '#000';
const lightText = '#fff';

const flex = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const fill = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const THEME_DEFAULTS = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
    // MuiButton: {
    //   containedSecondary: {
    //     '&:hover': {
    //       backgroundColor: 'pink',
    //     },
    //   },
    // },
  },
  spacing: 10,
  flex,
  fill,
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: 'sofia-pro',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#639FC3',
    },
    error: {
      main: '#cc1313',
    },
    background: {
      dark: '#000',
      light: '#fff',
      lightGrey: '#F1F4F7',
    },
    text: {
      secondary: '#000',
      primary: '#000',
      light: {
        dark: tinycolor(lightText)
          .darken(60)
          .toString(),
        main: lightText,
        light: tinycolor(lightText)
          .lighten()
          .toString(),
      },
      dark: {
        dark: tinycolor(darkText)
          .darken()
          .toString(),
        main: darkText,
        light: tinycolor(darkText)
          .lighten(60)
          .toString(),
      },
    },
    icons: {
      main: '#000',
    },
  },
  button: {
    primary: {
      main: '#639FC3',
    },
    dark: {},
    secondary: {},
  },
};

export const AGENT_THEME_DEFAULTS = {
  spacing: 10,
  flex,
  fill,
  typography: {
    useNextVariants: true,
    fontSize: 14,
    fontFamily: 'sofia-pro',
  },
  palette: {
    // TODO: figure out how to potentially deep merge this w/ THEME_DEFAULTS
    background: {
      default: '#fff',
      dark: '#000',
      light: '#fff',
      lightGrey: '#F1F4F7',
    },
    primary: {
      main: '#000',
    },
    warn: {
      light: '#D6785A',
      main: '#BC5E40',
    },
    secondary: {
      main: defaultTheme.secondary,
    },
    success: {
      main: '#00AF00',
    },
    text: {
      secondary: '#000',
      primary: '#000',
      sub: '#7B7B7B',
      light: {
        dark: tinycolor(lightText)
          .darken(60)
          .toString(),
        main: lightText,
        light: tinycolor(lightText)
          .lighten()
          .toString(),
      },
      dark: {
        dark: tinycolor(darkText)
          .darken()
          .toString(),
        main: darkText,
        light: tinycolor(darkText)
          .lighten(60)
          .toString(),
      },
    },
    icons: {
      main: '#000',
    },
  },
  button: {
    primary: {
      main: '#639FC3',
    },
  },
};

export const AGENT_DATA_TO_THEME_PATH_MAP = {
  secondaryThemeColor: 'palette.secondary.main',
  primaryThemeColor: 'palette.primary.main',
  primaryFont: 'typography.fontFamily',
  backgroundColor: 'palette.background.default',
  themeType: 'palette.mode',
};

export const FONT_LIST = [
  'sofia-pro',
  'SofiaProSoft',
  'Comic Sans MS',
  'Roboto',
  'Helvetica',
  'Arial',
  'Times New Roman',
  'Times',
  'Courier New',
  'Courier',
  'Verdana',
  'Georgia',
  'Palatino',
  'Garamond',
  'MillerTextRoman',
];

export const FONT_LABELS = {
  'sofia-pro': 'Sofia Pro',
  SofiaProSoft: 'Sofia Pro Soft',
  ComicSansMS: 'Comic Sans MS',
  Roboto: 'Roboto',
  Helvetica: 'Helvetica',
  Arial: 'Arial',
  TimesNewRoman: 'Times New Roman',
  Times: 'Times',
  CourierNew: 'Courier New',
  Courier: 'Courier',
  Verdana: 'Verdana',
  Georgia: 'Georgia',
  Palatino: 'Palatino',
  Garamond: 'Garamond',
  MillerTextRoman: 'Miller Text Roman',
};
