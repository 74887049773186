export const rateLimits = {
  // mathMax: eg: to prevent /0.
  // override: the "alternate" default.
  land: { min: 0, max: 50, mathMax: 99, default: 20, override: 3 },
  air: { min: 0, max: 50, mathMax: 99, default: 20, override: 3 },
  deposit: { min: 0, max: 100, default: 50, override: 3 },
  currency: { min: 0, max: 50, mathMax: 99, default: 3 },
  dmc: { min: 0, max: 50, default: 3, override: 20 },
  rack: { min: 0, max: 50, default: 6 },

  credit: { default: 3.5 },
  ach: { default: 1 },
  foreignSell: { default: 1 },
};
