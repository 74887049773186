import React from 'react';

const User = () => (
  <svg viewBox="0 0 13 15" fill="none">
    <path
      d="M6.5 7.75C8.41406 7.75 10 6.19141 10 4.25C10 2.33594 8.41406 0.75 6.5 0.75C4.55859 0.75 3 2.33594 3 4.25C3 6.19141 4.55859 7.75 6.5 7.75ZM6.5 1.625C7.92188 1.625 9.125 2.82812 9.125 4.25C9.125 5.69922 7.92188 6.875 6.5 6.875C5.05078 6.875 3.875 5.69922 3.875 4.25C3.875 2.82812 5.05078 1.625 6.5 1.625ZM7.86719 9.0625H5.10547C2.48047 9.0625 0.375 11.1953 0.375 13.8203C0.375 14.3398 0.785156 14.75 1.30469 14.75H11.668C12.1875 14.75 12.625 14.3398 12.625 13.8203C12.625 11.1953 10.4922 9.0625 7.86719 9.0625ZM11.668 13.875H1.30469C1.27734 13.875 1.25 13.8477 1.25 13.8203C1.25 11.6875 2.97266 9.9375 5.10547 9.9375H7.86719C10 9.9375 11.75 11.6875 11.75 13.8203C11.75 13.8477 11.6953 13.875 11.668 13.875Z"
      fill="currentColor"
    />
  </svg>
);

export default User;
