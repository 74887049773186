import React, { useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import clsx from 'clsx';
import sharedStyles from 'styles/shared';

import BaseModal from 'components/v2/Modals/BaseModal';
import { useUser } from 'common/hooks/user';

import { Tabs, Tab } from '@mui/material';
import TabPanel from 'components/TabPanel';
import NewClientForm from 'components/v2/Forms/NewClientForm';
import ClientSelector from 'components/Itinerary/EditItinerary/Travelers/ClientSelector';

import AsyncButton from '../AsyncButton';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
  panels: {
    paddingTop: theme.spacing(2),
  },
}));

/**
 * @todo: this is a ghetto layer on top of an existing modal, we should really properly clean this up at some point!
 */
export default NiceModal.create(function CreateAddClientModal(props) {
  const {
    client,
    showSelect = true,
    showCreate = true,
    title = 'Assign a Client',
    subtext = 'Add or change a client to your itinerary',
    selectSaveText = 'Assign Client',
    createSaveText = 'Create and Assign Client',
    invertTabs = false,
    canDelete,
    onDelete,
    onNewClient,
    onSelectExistingClient,
    loading,
    dedupeIds = [],
    agencyId,
  } = props;
  const s = sharedStyles();
  const classes = useStyles();
  const user = useUser();
  const modal = useModal();
  const [activeTab, setActiveTab] = useState(0);
  const [existingClient, setExistingClient] = useState(client ? client : undefined);
  const formRef = React.useRef();

  const clients = useMemo(() => {
    if (user?.clients) {
      return user.clients
        .filter(client => !dedupeIds.includes(client.id))
        .filter(client => client.id !== user.client?.id)
        .sort((a, b) => a.name?.localeCompare(b.name));
    }
    return [];
  }, [user?.clients, dedupeIds, user.client?.id]);

  const { tabs: TABS, saveTexts: SAVE_TEXTS } = useMemo(() => {
    let tabs = [];
    let saveTexts = [];
    if (showSelect) {
      tabs.push({
        label: 'Select Existing Client',
        teardown: true,
      });
      saveTexts.push(selectSaveText);
    }
    if (showCreate) {
      tabs.push({
        label: 'Create New Client',
        teardown: true,
      });
      saveTexts.push(createSaveText);
    }

    if (invertTabs) {
      tabs = tabs.reverse();
      saveTexts = saveTexts.reverse();
    }
    return { tabs, saveTexts };
  }, [showSelect, showCreate, selectSaveText, createSaveText, invertTabs]);

  const handleClose = () => {
    if (loading) return;
    modal.hide();
  };

  const handleCancel = () => {
    if (loading) return;
    modal.hide();
  };

  const handleContinue = async () => {
    if (loading) return;
    // this uses an imperativeHandle to submit the form
    // **inside** NewClientForm. It validates the form then
    // fires props.onSubmit (onNewClientSubmit) when validation succeeds
    if (SAVE_TEXTS[activeTab] === createSaveText) {
      formRef?.current?.submit();
    } else {
      onSelectExistingClient(existingClient, modal);
    }
  };

  const onNewClientSubmit = async data => onNewClient(data, modal);

  const selectExistingClient = client => setExistingClient(client);

  let tabs = TABS.map(tab => tab && <Tab label={tab.label} key={tab.label} />);

  let panels = [];
  if (showSelect) {
    panels.push(<ClientSelector clients={clients} selectedClient={existingClient} onSelect={selectExistingClient} idKey="id" />);
  }
  if (showCreate) {
    panels.push(<NewClientForm ref={formRef} onSubmit={onNewClientSubmit} agencyId={agencyId} />);
  }
  if (invertTabs) {
    panels = panels.reverse();
  }

  const tabPanels = panels.map((panel, index) => (
    <TabPanel key={index} value={activeTab} index={index}>
      {panel}
    </TabPanel>
  ));

  const disableAssign = TABS[activeTab].label === 'Select Existing Client' && !existingClient;

  return (
    <BaseModal
      // @todo: need to audit these, it's using a old BaseModal
      title={title}
      subtext={subtext}
      onClose={handleClose}
      onCancel={handleCancel}
      loading={loading}
      // new ish
      controls={
        <div className={clsx(s.flexRowSpaced, !canDelete && s.justifyEnd, s.gapMd, s.flex1, s.itemsCenter)}>
          {canDelete && (
            <AsyncButton onClick={() => onDelete(client, modal)} variantColor="error">
              Delete
            </AsyncButton>
          )}
          <div className={clsx(s.flexRow, s.gapSm)}>
            <Button onClick={modal.hide}>Cancel</Button>
            <AsyncButton variant="contained" color="primary" loading={loading} onClick={handleContinue} disabled={disableAssign}>
              Assign Client
            </AsyncButton>
          </div>
        </div>
      }
      maxWidth="md"
      fullWidth
    >
      {tabs.length > 1 && (
        <Tabs value={activeTab} onChange={(_, tab) => !loading && setActiveTab(tab)} className={classes.tabs}>
          {tabs}
        </Tabs>
      )}
      <div className={classes.panels}>{tabPanels}</div>
    </BaseModal>
  );
});
