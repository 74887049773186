import React, { useEffect } from 'react';
//import ReactDOM from 'react-dom'; // react17
import { createRoot } from 'react-dom/client'; // react18
import { Provider } from 'react-redux';
import { setupSentry } from 'common/utils/sentryBrowser';
import './index.css';
import { BrowserRouter, withRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import App from './App';
import { StyledEngineProvider } from '@mui/material/styles';

import DebugRouter from 'common/utils/DebugRouter';

import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Error from 'common/components/Error';
import * as Sentry from '@sentry/react';
import { routes } from 'routes';
import { store } from './store';
import logger from 'itrvl-logger';
const log = logger(__filename);

const generateClassName = createGenerateClassName({
  productionPrefix: 'wa',
});

let Router = BrowserRouter;

if (process.env.NODE_ENV === 'development') {
  Router = DebugRouter;
  log.debug('Running in Development mode');
}

const fallback = ({ error, componentStack, eventId }) => {
  log.warn(`Caught Client Agent ${eventId}, ${error}, ${componentStack}`);
  return <Error eventId={eventId} withSentry={process.env.REACT_APP_ENVIRONMENT !== 'production'} />;
};

const SentrySetup = withRouter(({ history, children }) => {
  useEffect(() => {
    setupSentry('agent', history, routes);
  }, [history]);

  return children;
});
const BaseApp = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Router>
        <SentrySetup>
          <Sentry.ErrorBoundary fallback={fallback}>
            <Provider store={store}>
              <StylesProvider generateClassName={generateClassName}>
                <DragDropContextProvider backend={HTML5Backend}>
                  <App />
                </DragDropContextProvider>
              </StylesProvider>
            </Provider>
          </Sentry.ErrorBoundary>
        </SentrySetup>
      </Router>
    </StyledEngineProvider>
  );
};

//ReactDOM.render(<BaseApp />, document.getElementById('root')); // react17
createRoot(document.getElementById('root')).render(<BaseApp />); // react18

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
