import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { AVAILABILITY_MODE, QUOTE_ONLY_MODE } from './constants';
import { useBuilderStore } from './store';
import ToggleButton from '@mui/material/ToggleButton';
import ItrvlIcon from 'common/components/Icons/ItrvlIcon';
import Tooltip from '../v2/Tooltip';

const useStyles = makeStyles({
  root: {},
  fillIcon: {
    '& path': {
      fill: '#000',
    },
  },
});

const options = [
  {
    label: 'Show only dates with availability',
    key: AVAILABILITY_MODE,
  },
  {
    label: 'Show all dates as available',
    key: QUOTE_ONLY_MODE,
  },
];

const QuoteModeFilter = () => {
  const classes = useStyles();
  const quoteMode = useBuilderStore(state => state.data.quoteMode);
  const setQuoteMode = useBuilderStore(state => state.actions.setQuoteMode);
  const text = options.find(o => o.key !== quoteMode)?.label;
  const onToggleBtn = useCallback(() => {
    setQuoteMode(options.find(o => o.key !== quoteMode)?.key);
  }, [quoteMode, setQuoteMode]);
  return (
    <Tooltip title={text} placement="bottom">
      <ToggleButton
        aria-label="availability selector"
        label="availability selector"
        className={classes.fillIcon}
        selected={quoteMode !== AVAILABILITY_MODE}
        onClick={onToggleBtn}
      >
        <ItrvlIcon className={classes.fillIcon} name="calendar"></ItrvlIcon>
      </ToggleButton>
    </Tooltip>
  );
};

export default QuoteModeFilter;
