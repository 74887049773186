import path from 'path';
import loglevel from 'loglevel';
import prefixer from 'loglevel-plugin-prefix';
import stripAnsi from 'strip-ansi';
import { map, replace, get, sample, filter } from 'lodash';
const humanize = require('ms');

function enableMatching(logger, env, level) {
  if (env && env != '') {
    const parts = env.split(/[ ,]/);
    map(parts, part => {
      const pattern = '^' + replace(part, '*', '.*') + '$';
      const test = new RegExp(pattern);
      if (logger.name.match(test)) {
        logger.setLevel(level);
      }
    });
  }
}

// In test we return a constant logger
// to make it easy to mock and verify
// logging stuff and silence
// logging output durring tests
let theTestLogger;
const testLogger = () => {
  if (!theTestLogger) {
    theTestLogger = {
      name: 'itrvl:test:logger',
      setLevel: () => {},
      trace: () => {},
      debug: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
      time: () => {},
      timeEnd: () => {},
    };
  }

  return theTestLogger;
};

// Flag to let us know we are testing the logger code
let loggerTest = process.env.LOG_TESTS || false;

const depth = 5;

const logger = function(loggerName) {
  const getGlobalThis = () => {
    if (typeof globalThis !== 'undefined') return globalThis;
    if (typeof self !== 'undefined') return self;
    if (typeof window !== 'undefined') return window;
    if (typeof global !== 'undefined') return global;
    if (typeof this !== 'undefined') return this;
    throw new Error('Unable to locate global `this`');
  };

  const g = getGlobalThis();

  const get = () => {
    const orig = Error.prepareStackTrace;
    Error.prepareStackTrace = (_, stack) => stack;
    let { stack } = new Error();
    Error.prepareStackTrace = orig;
    // On Safari the stack comes as a big string instead of
    // an array so let's break it up into an array like
    // we get on Chrome and emulate Chrome's CallSite
    if (typeof stack == 'string') {
      stack = map(stack.split('\n'), line => {
        line = line.replace(/https?:\/\/[^\/]+\//, '');
        return {
          getTypeName: () => {
            return line.split('@')[0].split('/')[0];
          },
          getMethodName: () => {
            const parts = line
              .replace('<', '')
              .split('@')[0]
              .split('/');
            if (parts.length > 1) {
              return parts[1];
            }
            return 'Unknown';
          },
          getLineNumber: () => {
            const parts = line.split(':');
            if (parts.length > 2) {
              return line.split(':')[2];
            }
            return 'Unknwon';
          },
          getFunctionName: () => {
            const func = line.split('@')[0];
            // If it has a slash in it then assume it is a method / function pair on Firefox
            if (func.indexOf('/') >= 0) {
              return null;
            }
            return func;
          },
          getFileName: () => {
            const parts = line.split('@');
            if (parts.length > 1) {
              return parts[1].split(':')[0];
            }
            return 'Unknown';
          },
        };
      });
    }
    return stack;
  };

  const stackUnroll = (d = depth) => {
    return __stack[d] || stackUnroll(d--);
  };

  if (Object.getOwnPropertyDescriptor(g, '__stack') === undefined) {
    Object.defineProperty(g, '__stack', {
      get,
    });
  }

  if (Object.getOwnPropertyDescriptor(g, '__line') === undefined) {
    Object.defineProperty(g, '__line', {
      get: () => stackUnroll(depth).getLineNumber(),
    });
  }

  if (Object.getOwnPropertyDescriptor(g, '__function') === undefined) {
    Object.defineProperty(g, '__function', {
      get: () => stackUnroll(depth).getFunctionName(),
    });
  }

  if (Object.getOwnPropertyDescriptor(g, '__file') === undefined) {
    Object.defineProperty(g, '__file', {
      get: () => stackUnroll(depth).getFileName(),
    });
  }

  if (!loggerName) {
    loggerName = 'server';
  } else {
    // Figure out what the root of our stuff is
    let prefix;
    let pkgName = '';
    // Is this a lambda or package?
    if (loggerName.indexOf('/lambda/') >= 0) {
      // Find the lambda prefix
      prefix = loggerName.substring(0, loggerName.indexOf('/', loggerName.indexOf('/lambda/') + 8));
    } else if (loggerName.indexOf('/packages/') >= 0) {
      // Find the package prefix
      prefix = loggerName.substring(0, loggerName.indexOf('/', loggerName.indexOf('/packages/') + 10));
      pkgName = prefix.slice(prefix.lastIndexOf('/') + 1).replace('itrvl-', '');
    } else {
      prefix = '/';
    }
    loggerName =
      pkgName +
      loggerName
        .replace(prefix, '')
        .replace(/^\/?\.\./, '')
        .replace(/^\/?src/, '')
        .replace(/^\/?lib/, '')
        .replace(/^\/?function/, '')
        // Swap slash for colons
        .replace(/\//g, ':')
        // Strip any double colons
        .replace(/::/, ':')
        // And strip the extension
        .replace(/\.js$/, '');
  }
  if (!loggerName.startsWith('itrvl:')) {
    loggerName = 'itrvl:' + path.basename(loggerName, '.js');
  }
  let logger;

  // Return testLogger in all tests EXCEPT the loggerTest
  if (process.env.NODE_ENV == 'test' && !loggerTest) {
    logger = testLogger();
  } else {
    logger = loglevel.getLogger(loggerName);

    // Setup methods around console.time and timeEnd
    logger.time = (...args) => {
      if (logger.getLevel() <= loglevel.levels.DEBUG) {
        console.time(...args);
      }
    };
    logger.timeEnd = (...args) => {
      if (logger.getLevel() <= loglevel.levels.DEBUG) {
        console.timeEnd(...args);
      }
    };
  }

  // Give users easy access to levels
  logger.levels = loglevel.levels;

  // Enable matching but higher levels take priority.
  // i.e DEBUG="itrvl:*" INFO="itrvl:*:itinerary" means everything except itinerary logs at DEBUG
  map(['TRACE', 'DEBUG', 'INFO', 'WARN', 'ERROR'], level => {
    enableMatching(logger, process.env[level], loglevel.levels[level]);
    enableMatching(logger, process.env[`REACT_APP_${level}`], loglevel.levels[level]);
  });

  // Give us a stack trace that excludes vendored stuff in the browser and this function itself
  // prettier-ignore
  logger.stackTrace = () => map(
    filter(get().slice(2), cs => cs.getFileName() && !cs.getFileName().includes('vendor')),
    cs => cs.getFunctionName() ? cs.getFunctionName() : `${cs.getTypeName()}.${cs.getMethodName()}`
  );

  return logger;
};

// Exposed for testing
if (process.env.NODE_ENV !== 'production') {
  logger._enableMatching = enableMatching;
}

// Expose some things at the root
logger.levels = loglevel.levels;
logger.setDefaultLevel = loglevel.setDefaultLevel;
logger.getLoggers = loglevel.getLoggers;
logger.getLogger = loglevel.getLogger;

logger.setup = function(server = true, useSentry = true) {
  let defaultLevel = loglevel.levels.WARN;
  switch (get(process.env, 'ITRVL_ENV', get(process.env, 'REACT_APP_ITRVL_ENV', 'production'))) {
    case 'integration':
    case 'staging':
    case 'sandbox':
    case 'development':
      defaultLevel = loglevel.levels.INFO;
      break;
  }
  loglevel.setDefaultLevel(defaultLevel);

  const sentryDsn =
    process.env.SENTRY_API_DSN ||
    process.env.SENTRY_JOBS_DSN ||
    process.env.REACT_APP_SENTRY_AGENT_DSN ||
    process.env.REACT_APP_SENTRY_CLIENT_DSN ||
    process.env.SENTRY_EXPRESS_AGENT_DSN ||
    process.env.SENTRY_EXPRESS_CLIENT_DSN;

  if (!process.env.REACT_APP_BYPASS_SENTRY_NODE && sentryDsn && useSentry) {
    const Sentry = require('@sentry/node');
    const originalFactory = loglevel.methodFactory;
    loglevel.methodFactory = function(methodName, logLevel, loggerName) {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);

      let strippedMessage; // for stripping ANSI from sentry message when in development

      return function() {
        const message = Object.keys(arguments).map((key, index) => arguments[key]);
        switch (methodName) {
          case 'error':
            if (message && message[1] instanceof Error) {
              if (get(message[1], 'skipSentry') === true) {
                break;
              } else {
                Sentry.captureMessage(message[1], { level: 'info' });
                break;
              }
            }
            if (message && Array.isArray(message)) {
              strippedMessage = map(message, m => {
                if (typeof m === 'String') {
                  return stripAnsi(m).replace(/>.*?>/, '');
                }
                return m;
              });
              Sentry.captureMessage(strippedMessage.join(' '), { level: 'info' });
            } else {
              Sentry.captureMessage(message, { level: 'info' });
            }
            break;
          default:
            // Sentry.captureEvent for log.warn is very noisy and doesn't properly preserve request context.
            // @TODO: insert breadcrumbs into sentry for API in a different manner and determine if warn level is worth capturing somewhere (not here)
            // Sentry.addBreadcrumb({ level: 'info', message: stripAnsi(message[0]) }); // captureMessage(message, level?) ignores level.
            break;
        }
        rawMethod.apply(undefined, arguments);
      };
    };
    loglevel.setLevel(loglevel.getLevel()); // Be sure to call setLevel method in order to apply plugin.
  }

  switch (process.env.NODE_ENV) {
    case 'production':
      prefixer.reg(loglevel);
      prefixer.apply(loglevel, {
        format(level, name, timestamp) {
          const pad = level.length < 5 ? ' ' : '';
          return `> ${timestamp} ${level.toUpperCase()}${pad} > ${name}`;
        },
      });
      loglevel.setDefaultLevel(loglevel.levels.WARN);
      break;
    case 'test':
      break;
    default:
      if (server) {
        const chalk = require('chalk');

        // Cache of color names for our loggers
        const colorNames = {};

        // Colors chalk offers that look okay
        // on both black and white backgrounds
        const availableColors = [
          'red',
          'green',
          'yellow',
          'blue',
          'magenta',
          'cyan',
          'redBright',
          'greenBright',
          'yellowBright',
          'blueBright',
          'magentaBright',
          'cyanBright',
        ];

        // Colors for log levels
        const levelColors = {
          TRACE: chalk.magenta,
          DEBUG: chalk.cyan,
          INFO: chalk.green,
          WARN: chalk.yellow,
          ERROR: chalk.red,
        };

        const getColorForName = name => {
          let nameColor = colorNames[[name]];
          if (!nameColor) {
            // Assign a random color
            nameColor = colorNames[name] = sample(availableColors);
          }
          return nameColor;
        };

        let prevTime;
        prefixer.reg(loglevel);
        prefixer.apply(loglevel, {
          format(level, name, timestamp) {
            const func = __function ? ` ${__function}() ` : ' ';
            const line = __line ? `${__line}` : '';
            const filePath = __file ? `${path.basename(__file)}` : '';
            const fileInfo = filePath ? `${filePath}:${line}${func}` : '';

            const extendedInfo = process.env.EXTENDED_LOGGING ? fileInfo : name;

            const nameColor = getColorForName(name);
            const pad = level.length < 5 ? ' ' : '';
            const curr = Number(new Date());
            const ms = curr - (prevTime || curr);
            prevTime = curr;
            return `> ${levelColors[level.toUpperCase()].inverse(level)}${pad} ${chalk[nameColor](humanize(ms))}\t> ${extendedInfo}\t`;
          },
        });
      } else {
        prefixer.reg(loglevel);
        if (process.env.NODE_ENV === 'development') {
          prefixer.apply(loglevel, {
            format(level, name, timestamp) {
              return `> ${name} >`;
            },
          });
        } else {
          prefixer.apply(loglevel, {
            format(level, name, timestamp) {
              const pad = level.length < 5 ? ' ' : '';
              return `> [${timestamp}] ${level.toUpperCase()}${pad} ${name} >`;
            },
          });
        }
      }
      break;
  }
};

// Visible for testing
logger.setLoggerTest = val => (loggerTest = val);

export default logger;
