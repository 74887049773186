import { useContext, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { forEach } from 'lodash';
import clsx from 'clsx';

import { ThemeContext } from 'common/context/ThemeContext';
import { useApi } from 'common/hooks/api';
import { Button } from '@mui/material';
import DetailSegments from './DetailSegments';
import Image from './Image';
import AvailabilityCalendar from './AvailabilityCalendar';
import { useBuilderStore } from './store';

const GUTTER = 37;
const useStyles = makeStyles(
  theme => ({
    root: {
      inset: 0,
      backgroundColor: '#f7f4f1',
      zIndex: 3,
      overflowY: 'auto',
      // so we can scroll the container with the horizontal nav below
      paddingBottom: 100,

      position: 'fixed',
      // unsure why this is needed, chrome was crossing out the style
      top: '69px !important',

      '& .ib2-ac-current-selection': {
        '& > div > span': {
          backgroundColor: theme.palette.primary.main,
          color: '#fff',
        },
      },
    },
    content: {
      position: 'static',
    },
    hero: {
      position: 'relative',
      display: 'flex',
      gap: 40,
      padding: GUTTER,
    },
    data: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      gap: 40,
    },
    info: {
      position: 'relative',
      flex: 1,
      '& h1': {
        color: '#fff',
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 48,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    back: {
      color: '#fff',
    },
    image: {
      position: 'absolute',
      inset: 0,
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    overlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    calendar: {
      position: 'relative',
      backgroundColor: '#fff',
    },
    // about
    about: {
      display: 'grid',
      gridTemplateColumns: '300px 1fr',
      gap: 80,
      padding: GUTTER,
      borderBottom: '1px solid #E9E1D7',
      '& h2': {
        margin: 0,
        fontFamily: 'Athelas',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 36,
        lineHeight: 1.1666666667,
        letterSpacing: '0.04em',
        textTransform: 'uppercase',
      },
    },
    // rooms
    allRooms: {
      padding: GUTTER,
      paddingBottom: 200,
    },
    rooms: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
    room: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      justifyContent: 'flex-end',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
    },
    support: {
      paddingBottom: 50,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      '& svg': {
        color: theme.palette.primary.main,
        width: 40,
      },
    },
    features: {},
    featuresSection: {
      paddingTop: 25,
      marginTop: 12,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    onlineIcon: {
      width: 25,
      height: 'auto',
    },
  }),
  {
    name: 'ItineraryBuilderDetailsContent',
  },
);

export default function OwnDetailsContent(props) {
  const classes = useStyles();
  const { id } = props;
  const Api = useApi();
  const { customizations = {} } = useContext(ThemeContext);
  const { showRestOfWorld } = customizations;

  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const { supplierCode, campInfo, campName } = segment;
  const editSegmentAtIndex = useBuilderStore(state => state.actions.accommodations.editSegmentAtIndex);
  const handleClickDay = useBuilderStore(state => state.actions.segments.selectDate);

  const setRegions = useBuilderStore(state => state.actions.regions.setRegions);

  const loadRegions = useCallback(async () => {
    if (!Api || !setRegions) return;
    const query = {
      include: {
        relation: 'regions',
        scope: {
          fields: ['countryRegionCode', 'name', 'countryCode', 'regionCode'],
        },
      },
      fields: ['countryCode', 'name'],
    };
    if (!showRestOfWorld) {
      query.where = {
        restOfWorld: { neq: true },
      };
    }
    const countries = await Api.getCountries(query);

    const regions = countries.data.reduce((agg, country) => {
      forEach(country.regions, region => {
        agg.push({
          ...region,
          country: country.name,
        });
      });
      return agg;
    }, []);
    setRegions(regions);
  }, [Api, showRestOfWorld, setRegions]);

  useEffect(() => {
    loadRegions();
  }, [loadRegions]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.hero}>
          {/* @todo: backdrop image goes here! */}
          <div className={classes.image}>
            <div className={classes.overlay} />
            <Image s3Key={segment?.featuredImage?.s3Key} />
          </div>

          <div className={clsx(classes.data, 'inner')}>
            <div className={classes.info}>
              <Button className={classes.back} onClick={() => editSegmentAtIndex(null)}>
                <ChevronLeftIcon /> View All Accommodations
              </Button>
              <h1>{campName}</h1>
            </div>
            <div className={classes.calendar}>
              <AvailabilityCalendar supplierCode={supplierCode} data={segment.item} onClick={handleClickDay} />
            </div>
          </div>
        </div>

        <DetailSegments supplierCode={supplierCode} noRoomSelections={true} isOwnAccommodation />

        {campInfo && (
          <div className={clsx(classes.about, 'inner')}>
            <div>
              <h2>About {campName}</h2>
              <div className={classes.featuresSection}>
                <div className={classes.features}></div>
              </div>
            </div>
            <div>{campInfo}</div>
          </div>
        )}
      </div>
    </div>
  );
}
