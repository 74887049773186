import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import CalendarIcon from './icons/Calendar';

import { formatDigestDates } from './utils';
import { useBuilderStore } from './store';

const GUTTER = 20;
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: GUTTER,
    top: 0,
    zIndex: 1,
    borderRadius: 4,
    transition: theme.transitions.create(['background-color', 'color']),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },

  icon: {
    width: 25,
    lineHeight: 0,
    // height: 30,
    '& svg': {
      width: '100%',
      height: 'auto',
      color: '#fff',
      transition: theme.transitions.create(['color']),
    },
  },
  calendar: {
    width: 20,
    // height: 20,
  },
}));

const DetailDmcSegment = props => {
  const classes = useStyles();
  const { id } = props;
  const segment = useBuilderStore(state => state.data.segmentsById[id]);
  const dateText = formatDigestDates(segment.startDate, segment.endDate);
  return (
    <div className={classes.root}>
      <div className="inner">
        <div className={classes.item}>
          <div className={clsx(classes.icon, classes.calendar)}>
            <CalendarIcon />
          </div>
          {dateText || 'No Dates Selected'}
        </div>
      </div>
    </div>
  );
};

export default DetailDmcSegment;
