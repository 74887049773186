// page limit for all api request
export const PAGE_LIMIT = 20;
export const CHILDREN_MAXIMUM_AGE = 17;
export const DIGEST_DATE_FORMAT = 'MMMM d, yyyy';
export const DATE_FORMAT_INTERNAL = 'yyyy-MM-dd';
export const WEEK_OFFSET = 5;
export const STORE_LOCAL_STORAGE_KEY = 'itinerary-builder';
// availability modes
export const QUOTE_ONLY_MODE = 'quote_only';
export const AVAILABILITY_MODE = 'availability';
