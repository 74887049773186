export const isImage = type => /^image/i.test(type);
export const isVideo = type => /^video/i.test(type);
export const isAudio = type => /^audio/i.test(type);

export const getFileType = type => {
  if (isImage(type)) {
    return 'Photo';
  }
  if (isVideo(type)) {
    return 'Video';
  }
  if (isAudio(type)) {
    return 'Audio';
  }
  return 'Unknown';
};
