import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import DetailSegment from './DetailSegment';
import DetailDmcSegment from './DetailDmcSegment';
import DetailOwnAccommodationSegment from './DetailOwnAccommodationSegment';

import { useSupplierSegments } from './hooks';
import { OWN_ACCOMMODATION_SUPPLIER_CODE } from 'itrvl-types';

const GUTTER = 20;
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    padding: GUTTER,
    zIndex: 1,
  },
  noRooms: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 10,
  },
}));

const DetailSegments = ({ supplierCode, noRoomSelections }) => {
  const classes = useStyles();
  const segments = useSupplierSegments(supplierCode);
  return (
    <div className={classes.root}>
      <div className={clsx('inner', noRoomSelections && classes.noRooms)}>
        {segments.map(segment => {
          if (supplierCode === OWN_ACCOMMODATION_SUPPLIER_CODE) return <DetailOwnAccommodationSegment key={segment.id} id={segment.id} />;
          return noRoomSelections ? (
            <DetailDmcSegment key={segment.id} id={segment.id} />
          ) : (
            <DetailSegment key={segment.id} id={segment.id} />
          );
        })}
      </div>
    </div>
  );
};

export default DetailSegments;
